import { TFunction } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { TDispatch } from '../../..';
import { Routes } from '../../../classes/Routes';
import DealEdit from '../../../components/Deals/Deal/DealEdit/DealEdit';
import DealHandoverDialog from '../../../components/Deals/Deal/DealEdit/DealHandoverDialog/DealHandoverDialog';
import DealMaintenanceInfoDialog from '../../../components/Deals/Deal/DealEdit/DealMaintenanceInfoDialog/DealMaintenanceInfoDialog';
import DealSendDialog from '../../../components/Deals/Deal/DealEdit/DealSendDialog/DealSendDialog';
import DealTerminateDialog from '../../../components/Deals/Deal/DealEdit/DealTerminateDialog/DealTerminateDialog';
import DealLog from '../../../components/Deals/Deal/DealLog/DealLog';
import DealPreview from '../../../components/Deals/Deal/DealPreview/DealPreview';
import DealTerminate from '../../../components/Deals/Deal/DealTerminate/DealTerminate';
import { EDealVariation } from '../../../components/Deals/Deal/DealVariation/DealVariationEdit/DealVariationEdit';
import Alert from '../../../components/UI/Alert/Alert';
import { EContentType } from '../../../components/UI/Dropzone/Dropzone';
import Input, { EInputType, IInputField, IInputOptions, IOption } from '../../../components/UI/Input/Input';
import ModalContext, { EModalSize, defaultModal } from '../../../components/UI/Modal/ModalContext';
import Spinner from '../../../components/UI/Spinner/Spinner';
import DealContext from '../../../context/DealContext';
import { EInputUpdateAction } from '../../../context/InputContext';
import { EDealStatus, EDealType, EInvoicePayer, ELeasingModel, InputSuffix, LEASING_MODEL_OPTIONS } from '../../../enums';
import { useAuthUser } from '../../../hooks/useAuthUser';
import {
  IAppState,
  IAttachment, ICondition,
  IDeal,
  IDealExtraFee, IDealPricingVariation,
  IDealUser,
  IDealership,
  IFinanceCompany,
  IOrganization,
  IPipedriveDeal,
  IResidualValueRequest
} from '../../../interfaces';
import { defaultDeal, defaultExtraFee, defaultPricingVariation } from '../../../shared/deal-utils';
import { INVALID_DEFAULT, VALID_DEFAULT, dateToString, generateUUID, initForm, isValidEmail, updateInputHandler } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import classes from './Deal.module.scss';
import DealNotes from './DealNotes/DealNotes';
import { VAT_PERCENT_OPTIONS } from '../../../shared/vat';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {
  pipedriveDeal?: IPipedriveDeal | null;
}

export enum EDealView {
  EDIT = "edit",
  PREVIEW = "preview",
  TERMINATE = "terminate",
}

export enum EDeal {
  VIEW = "view",
}

export enum EDealEdit {
  TYPE = "type",
  FINANCE_COMPANY = "financeCompany",
  LEASING_MODEL = "leasingModel",
  NAME = "name",
  CUSTOMER = "customer",

  // asiakkaan kentät
  CUSTOMER_NAME = "customerName",
  CUSTOMER_BUSINESS_ID = "customerBusinessId",
  CUSTOMER_STREET_ADDRESS = "customerStreetAddress",
  CUSTOMER_ZIP = "customerZip",
  CUSTOMER_CITY = "customerCity",
  contactPersonName = "contactPersonName",
  contactPersonEmail = "contactPersonEmail",
  contactPersonPhoneNumber = "contactPersonPhoneNumber",

  // myyjäliikkeeseen liittyvät kentät, lisää 'auto' eteen jos auto
  DEALERSHIP = "dealership",
  DEALERSHIP_SELLER_NAME = "dealershipSellerName",
  DEALERSHIP_SALE_NUMBER = "dealershipSaleNumber",

  // koneeseen/autoon liittyvät kentät
  BRAND = "brand",
  MODEL = "model",
  CO2_EMISSIONS = "co2Emissions",
  // vapaa autoetu/käyttöetu
  TAKE_HOME_VEHICLE = "takeHomeVehicle",
  CAR_BENEFIT = "carBenefit",

  REGISTRATION_NUMBER = "registrationNumber",

  // rekisteröintiin liittyvät kentät
  REGISTRATION_DATE = "registrationDate",
  SIGNING_DATE = "signingDate",
  SURETY = "surety",
  HANDOVER_DATE = "handoverDate",

  // vakuutus
  INSURANCE = "insurance",
  // lisävarusteet
  ACCESSORIES = "accessories",
  ACCESSORIES2 = "accessories2",

  // car pricing
  TOTAL_PRICE = "totalPrice",
  DISCOUNT = "discount",
  CAR_TAX = "carTax",

  // machine pricing
  TOTAL_PRICE_VAT = "totalPriceVAT",

  DEAL_PRICING_VARIATIONS = "dealPricingVariations",

  BASIC_SERVICES = "basicServices",
  ADDITIONAL_INFORMATION = "additionalInformation",
  ADDITIONAL_EXPENSES = "additionalExpenses",

  INVOICING_FEE = "invoicingFee",
  OPENING_FEE = "openingFee",
  ATTACHMENTS = "attachments",

  SERIAL_NUMBER = "serialNumber",
  USER_NAME = "userName",
  USER_EMAIL = "userEmail",
  USER_PHONE_NUMBER = "userPhoneNumber",
  USER_SOCIAL_SECURITY_NUMBER = "userSocialSecurityNumber",
  USER_STREET_ADDRESS = "userStreetAddress",
  USER_CITY = "userCity",
  USER_ZIP = "userZip",
  INVOICE_REFERENCE = "invoiceReference",
  COST_CENTER = "costCenter",
  INVOICE_PAYER = "invoicePayer",
  FINANCE_COMPANY_CONTRACT_NUMBER = "financeCompanyContractNumber",
  hideVatPrices = "hideVatPrices",
  insuranceCertificateReceived = "insuranceCertificateReceived",
  invoiceInfo = "invoiceInfo",
  modelYear = "modelYear",
  maintenanceServices = "maintenanceServices",
  vatPercent = "vatPercent",
  usedKilometers = "usedKilometers",
  usedHours = "usedHours",
}

export enum EBasicService {
  ACQUISITION = "acquisition",
  TIRES = "tires",
  RELOCATION = "relocation",
  REDEMPTION = "redemption",
  REALIZATION_FEE = "realizationFee",
  INSURANCE = "insurance",
}

export enum EMaintenanceService {
  MAINTENANCE = "maintenance",
  WEAR_AND_TEAR = "wearAndTear",
  EXTRA_TIRES = "extraTires",
  EXTRA_TIRES_EUROMASTER = "extraTiresEuromaster",
  EXTRA_TIRES_RENGAS_CENTER = "extraTiresRengasCenter",
  REPLACEMENT_CAR = "replacementCar",
  REPLACEMENT_CAR_SERVICE = "replacementCarService",
  TIRE_STORAGE = "tireStorage",
  TIRE_STORAGE_EUROMASTER = "tireStorageEuromaster",
  TIRE_STORAGE_RENGAS_CENTER = "tireStorageRengasCenter",
  MAINTENANCE_CONTRACT = "maintenanceContract",
  MAINTENANCE_CONTRACT_OLF = "maintenanceContractOLF",
  SCHEDULED_MAINTENANCE = "ScheduledMaintenance",
  SCHEDULED_MAINTENANCE_ONLY = "ScheduledMaintenanceOnly",
}

export enum EAdditionalInformation {
  LATEST_FINANCIAL_STATEMENT = "latestFinancialStatement",
  FINANCIAL_STATEMENT_INTERVALS = "financialStatementIntervals",
  IS_REPLACEMENT = "isReplacement",
  THIRD_PARTY_GUARANTEED = "thirdPartyGuaranteed",
  COLLATERAL_FREE = "collateralFree",
  CREDIT_ONGOING = "creditOngoing",
  CREDIT_ACCEPTED = "creditAccepted",
  CREDIT_DECLINED = "creditDeclined",
  CREDIT_CONDITIONAL = "creditConditional",
}

export const carBasicServices = (t: TFunction): ICondition[] => {
  return [
    { id: EBasicService.ACQUISITION, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_ACQUISITION) },
    { id: EBasicService.TIRES, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_TIRES) },
    { id: EBasicService.RELOCATION, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_RELOCATION) },
    { id: EBasicService.REDEMPTION, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_REDEMPTION), autoUpdate: true },
    { id: EBasicService.REALIZATION_FEE, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_REALIZATION_FEE) },
    { id: EBasicService.INSURANCE, accepted: false, text: t(ETranslation.DEAL_BASIC_SERVICE_INSURANCE) },
  ];
};

export const maintenanceServices = (t: TFunction): ICondition[] => {
  return [
    { id: EMaintenanceService.MAINTENANCE, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE) },
    { id: EMaintenanceService.WEAR_AND_TEAR, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_WEAR_AND_TEAR) },
    //{ id: EMaintenanceService.EXTRA_TIRES, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES), autoUpdate: true },
    { id: EMaintenanceService.EXTRA_TIRES_EUROMASTER, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_EUROMASTER), autoUpdate: true },
    { id: EMaintenanceService.EXTRA_TIRES_RENGAS_CENTER, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_RENGAS_CENTER), autoUpdate: true },
    { id: EMaintenanceService.REPLACEMENT_CAR, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR) },
    { id: EMaintenanceService.REPLACEMENT_CAR_SERVICE, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR_SERVICE) },
    //{ id: EMaintenanceService.TIRE_STORAGE, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE) },
    { id: EMaintenanceService.TIRE_STORAGE_EUROMASTER, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_EUROMASTER) },
    { id: EMaintenanceService.TIRE_STORAGE_RENGAS_CENTER, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_RENGAS_CENTER) },
    { id: EMaintenanceService.MAINTENANCE_CONTRACT, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT) },
    { id: EMaintenanceService.MAINTENANCE_CONTRACT_OLF, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT_OLF) },
    { id: EMaintenanceService.SCHEDULED_MAINTENANCE, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE) },
    { id: EMaintenanceService.SCHEDULED_MAINTENANCE_ONLY, accepted: false, text: t(ETranslation.DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE_ONLY) },
  ];
}

export const machineAdditionalInformation = (t: TFunction): ICondition[] => {
  return [
    { id: EAdditionalInformation.LATEST_FINANCIAL_STATEMENT, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_LATEST_FINANCIAL_STATEMENT) },
    { id: EAdditionalInformation.FINANCIAL_STATEMENT_INTERVALS, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_FINANCIAL_STATEMENT_INTERVALS) },
    { id: EAdditionalInformation.IS_REPLACEMENT, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_IS_REPLACEMENT) },
    { id: EAdditionalInformation.THIRD_PARTY_GUARANTEED, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_THIRD_PARTY_GUARANTEED) },
    { id: EAdditionalInformation.COLLATERAL_FREE, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_COLLATERAL_FREE) },
    { id: EAdditionalInformation.CREDIT_ONGOING, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_CREDIT_ONGOING) },
    { id: EAdditionalInformation.CREDIT_ACCEPTED, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_CREDIT_ACCEPTED) },
    { id: EAdditionalInformation.CREDIT_DECLINED, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_CREDIT_DECLINED) },
    { id: EAdditionalInformation.CREDIT_CONDITIONAL, accepted: false, text: t(ETranslation.DEAL_ADDITIONAL_INFORMATION_CREDIT_CONDITIONAL) },
  ];
};

const invoicePayerOptions: IOption[] = [
  { value: EInvoicePayer.FINANCE_COMPANY, labelTranslation: ETranslation.INVOICE_PAYER_FINANCE_COMPANY },
  { value: EInvoicePayer.OLF, labelTranslation: ETranslation.INVOICE_PAYER_OLF },
];

type TListValue =
  | IOrganization[]
  | IFinanceCompany[]
  | IDealership[];

interface IDealUpdate {
  status: EDealStatus;
  date: Date;
  notes?: string;
}

const dealViewOptions: IOption[] = [
  { value: EDealView.EDIT, labelTranslation: ETranslation.DEAL_VIEW_EDIT },
  { value: EDealView.PREVIEW, labelTranslation: ETranslation.DEAL_VIEW_PREVIEW },
];

const contractDealViewOptions: IOption[] = [
  ...dealViewOptions,
  { value: EDealView.TERMINATE, labelTranslation: ETranslation.DEAL_VIEW_TERMINATE },
];

const Deal: React.FC<IProps> = ({ match, history, pipedriveDeal, location }) => {
  const dispatch = useDispatch<TDispatch>();

  const [financeCompany, setFinanceCompany] = useState<IFinanceCompany | null>(null);
  const [customer, setCustomer] = useState<IOrganization | null>(null);

  const authUser = useAuthUser();

  const {
    dealsLoading, dealsError, deal, preOffer,
    customersLoading, customersError, customers,
    financeCompaniesLoading, financeCompaniesError, financeCompanies,
    dealershipsLoading, dealershipsError, dealerships,
    // invoicesLoading, invoicesError, invoices,
    biltemaError, biltemaResponse, netwheelsError
  } = useSelector((state: IAppState) => ({
    dealsLoading: state.deals.loading,
    dealsError: state.deals.error,
    deal: state.deals.deal,
    preOffer: state.deals.preOffer,
    customersLoading: state.customers.loading,
    customersError: state.customers.error,
    customers: state.customers.customers,
    financeCompaniesLoading: state.financeCompanies.loading,
    financeCompaniesError: state.financeCompanies.error,
    financeCompanies: state.financeCompanies.financeCompanies,
    dealershipsLoading: state.dealerships.loading,
    dealershipsError: state.dealerships.error,
    dealerships: state.dealerships.dealerships,
    biltemaError: state.biltema.error,
    biltemaResponse: state.biltema.biltemaResponse,
    netwheelsError: state.netwheels.error
  }));

  const { t } = useTranslation();
  const { id } = match.params;

  const isPreoffer = id.startsWith("preoffer");
  const isNew = id === "new" || isPreoffer;

  const [view, setView] = useState<IInputField>({
    [EDeal.VIEW]: {
      type: EInputType.radio,
      value: isNew ? EDealView.EDIT : EDealView.PREVIEW,
      validation: {
        required: true
      },
      options: dealViewOptions,
    },
  });

  const createInitialInputs = useCallback((t: TFunction) => {
    return ({
      [EDealEdit.TYPE]: {
        type: EInputType.radio,
        value: EDealType.CAR,
        options: [
          { value: EDealType.CAR, labelTranslation: ETranslation.DEAL_TYPE_CAR },
          { value: EDealType.MACHINE, labelTranslation: ETranslation.DEAL_TYPE_MACHINE }
        ]
      },
      [EDealEdit.FINANCE_COMPANY]: {
        type: EInputType.select,
        labelTranslation: ETranslation.DEAL_FINANCE_COMPANY,
        placeholderTranslation: ETranslation.DEAL_FINANCE_COMPANY,
        value: "",
        options: [],
        validation: {
          required: true,
        },
      },
      [EDealEdit.LEASING_MODEL]: {
        type: EInputType.select,
        labelTranslation: ETranslation.DEAL_LEASING_MODEL,
        placeholderTranslation: ETranslation.DEAL_LEASING_MODEL,
        value: ELeasingModel.REILU,
        options: LEASING_MODEL_OPTIONS,
      },
      [EDealEdit.NAME]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_NAME,
        placeholderTranslation: ETranslation.DEAL_NAME,
        value: "",
      },
      [EDealEdit.CUSTOMER]: {
        type: EInputType.select,
        labelTranslation: ETranslation.DEAL_CUSTOMER,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER,
        value: "",
        options: []
      },

      // asiakas
      [EDealEdit.CUSTOMER_NAME]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CUSTOMER_NAME,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER_NAME,
        value: "",
      },
      [EDealEdit.CUSTOMER_BUSINESS_ID]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CUSTOMER_BUSINESS_ID,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER_BUSINESS_ID,
        value: "",
      },
      [EDealEdit.CUSTOMER_STREET_ADDRESS]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CUSTOMER_STREET_ADDRESS,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER_STREET_ADDRESS,
        value: "",
      },
      [EDealEdit.CUSTOMER_ZIP]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CUSTOMER_ZIP,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER_ZIP,
        value: "",
      },
      [EDealEdit.CUSTOMER_CITY]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CUSTOMER_CITY,
        placeholderTranslation: ETranslation.DEAL_CUSTOMER_CITY,
        value: "",
      },
      [EDealEdit.contactPersonName]: {
        type: EInputType.text,
        labelTranslation: ETranslation.CONTACT_PERSON_NAME,
        placeholderTranslation: ETranslation.CONTACT_PERSON_NAME,
        value: "",
        validation: {
          required: true,
        },
      },
      [EDealEdit.contactPersonEmail]: {
        type: EInputType.email,
        labelTranslation: ETranslation.CONTACT_PERSON_EMAIL,
        placeholderTranslation: ETranslation.CONTACT_PERSON_EMAIL,
        value: "",
        validation: {
          required: true,
        },
      },
      [EDealEdit.contactPersonPhoneNumber]: {
        type: EInputType.text,
        labelTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
        placeholderTranslation: ETranslation.CONTACT_PERSON_PHONE_NUMBER,
        value: "",
        validation: {
          required: true,
        },
      },

      // myyjäliikkeeseen liittyvät kentät, lisää 'auto' eteen jos auto
      [EDealEdit.DEALERSHIP]: {
        type: EInputType.reactSelect,
        labelTranslation: ETranslation.DEAL_CAR_DEALERSHIP,
        placeholderTranslation: ETranslation.DEAL_CAR_DEALERSHIP,
        value: "",
        validation: {
          required: true,
        },
      },
      [EDealEdit.DEALERSHIP_SELLER_NAME]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CAR_DEALERSHIP_SELLER_NAME,
        placeholderTranslation: ETranslation.DEAL_CAR_DEALERSHIP_SELLER_NAME,
        value: "",
      },
      [EDealEdit.DEALERSHIP_SALE_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CAR_DEALERSHIP_SALE_NUMBER,
        placeholderTranslation: ETranslation.DEAL_CAR_DEALERSHIP_SALE_NUMBER,
        value: "",
      },

      // koneeseen/autoon liittyvät kentät
      // koneen/auton merkki/nimi
      [EDealEdit.BRAND]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_CAR_BRAND,
        placeholderTranslation: ETranslation.DEAL_CAR_BRAND,
        value: "",
      },
      [EDealEdit.MODEL]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_MODEL,
        placeholderTranslation: ETranslation.DEAL_MODEL,
        value: "",
      },
      [EDealEdit.CO2_EMISSIONS]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_CO2_EMISSIONS,
        placeholderTranslation: ETranslation.DEAL_CAR_CO2_EMISSIONS,
        post: InputSuffix.GRAMS_PER_KILOMETER,
        value: "",
      },

      // autoetu/käyttöetu?
      [EDealEdit.TAKE_HOME_VEHICLE]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_TAKE_HOME_VEHICLE,
        placeholderTranslation: ETranslation.DEAL_CAR_TAKE_HOME_VEHICLE,
        post: InputSuffix.EUROS_PER_MONTH(t),
        value: "",
      },
      [EDealEdit.CAR_BENEFIT]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_CAR_BENEFIT,
        placeholderTranslation: ETranslation.DEAL_CAR_CAR_BENEFIT,
        post: InputSuffix.EUROS_PER_MONTH(t),
        value: "",
      },

      [EDealEdit.REGISTRATION_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_REGISTRATION_NUMBER,
        placeholderTranslation: ETranslation.DEAL_REGISTRATION_NUMBER,
        value: "",
      },

      // rekisteröintiin liittyvät kentät
      [EDealEdit.REGISTRATION_DATE]: {
        type: EInputType.datepicker,
        labelTranslation: ETranslation.DEAL_REGISTRATION_DATE,
        placeholderTranslation: ETranslation.DEAL_REGISTRATION_DATE,
        value: null,
      },
      [EDealEdit.SIGNING_DATE]: {
        type: EInputType.datepicker,
        labelTranslation: ETranslation.DEAL_SIGNING_DATE,
        placeholderTranslation: ETranslation.DEAL_SIGNING_DATE,
        value: null,
      },
      [EDealEdit.SURETY]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_SURETY,
        placeholderTranslation: ETranslation.DEAL_SURETY,
        value: "",
      },
      [EDealEdit.HANDOVER_DATE]: {
        type: EInputType.datepicker,
        labelTranslation: ETranslation.DEAL_HANDOVER_DATE,
        placeholderTranslation: ETranslation.DEAL_HANDOVER_DATE,
        value: null,
      },

      // vakuutus
      [EDealEdit.INSURANCE]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_INSURANCE,
        placeholderTranslation: ETranslation.DEAL_INSURANCE,
        value: "",
        validation: {
          required: true,
        },
      },

      // lisävarusteet
      [EDealEdit.ACCESSORIES]: {
        type: EInputType.textarea,
        labelTranslation: ETranslation.DEAL_ACCESSORIES_LEFT,
        placeholderTranslation: ETranslation.DEAL_ACCESSORIES_LEFT,
        value: "",
      },
      [EDealEdit.ACCESSORIES2]: {
        type: EInputType.textarea,
        labelTranslation: ETranslation.DEAL_ACCESSORIES_RIGHT,
        placeholderTranslation: ETranslation.DEAL_ACCESSORIES_RIGHT,
        value: "",
      },

      // car pricing
      [EDealEdit.TOTAL_PRICE]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_MACHINE_PURCHASE_PRICE,
        placeholderTranslation: ETranslation.DEAL_MACHINE_PURCHASE_PRICE,
        post: InputSuffix.EUROS,
        value: "",
      },
      [EDealEdit.DISCOUNT]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_DISCOUNT,
        placeholderTranslation: ETranslation.DEAL_CAR_DISCOUNT,
        post: InputSuffix.EUROS,
        value: "",
      },
      [EDealEdit.CAR_TAX]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_CAR_TAX,
        placeholderTranslation: ETranslation.DEAL_CAR_CAR_TAX,
        post: InputSuffix.EUROS,
        value: "",
        validation: {
          required: true,
        },
      },

      [EDealEdit.TOTAL_PRICE_VAT]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_TOTAL_PRICE,
        placeholderTranslation: ETranslation.DEAL_CAR_TOTAL_PRICE,
        post: InputSuffix.EUROS,
        value: "",
        disabled: false,
      },

      [EDealEdit.BASIC_SERVICES]: {
        type: EInputType.conditionList,
        value: carBasicServices(t),
      },

      [EDealEdit.ADDITIONAL_INFORMATION]: {
        type: EInputType.conditionList,
        value: machineAdditionalInformation(t),
      },

      [EDealEdit.INVOICING_FEE]: {
        type: EInputType.number,
        labelTranslation: ETranslation.FINANCE_COMPANY_INVOICING_FEE,
        placeholderTranslation: ETranslation.FINANCE_COMPANY_INVOICING_FEE,
        post: InputSuffix.EUROS_PER_MONTH(t),
        value: "",
      },
      [EDealEdit.OPENING_FEE]: {
        type: EInputType.number,
        labelTranslation: ETranslation.FINANCE_COMPANY_OPENING_FEE,
        placeholderTranslation: ETranslation.FINANCE_COMPANY_OPENING_FEE,
        post: InputSuffix.EUROS,
        value: "",
      },

      [EDealEdit.ATTACHMENTS]: {
        type: EInputType.dropzone,
        labelTranslation: ETranslation.DEAL_ATTACHMENTS,
        value: [],
        config: { accept: EContentType.PDF, text: t(ETranslation.DROPZONE_TEXT_PDF), dragText: t(ETranslation.DROPZONE_DRAG_TEXT_PDF) },
        multiple: true,
      },

      [EDealEdit.SERIAL_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_SERIAL_NUMBER,
        placeholderTranslation: ETranslation.DEAL_SERIAL_NUMBER,
        value: "",
      },

      [EDealEdit.USER_NAME]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_NAME,
        placeholderTranslation: ETranslation.DEAL_USER_NAME,
        value: "",
      },
      [EDealEdit.USER_EMAIL]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_EMAIL,
        placeholderTranslation: ETranslation.DEAL_USER_EMAIL,
        value: "",
      },
      [EDealEdit.USER_PHONE_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_PHONE_NUMBER,
        placeholderTranslation: ETranslation.DEAL_USER_PHONE_NUMBER,
        value: "",
      },
      [EDealEdit.USER_SOCIAL_SECURITY_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_SOCIAL_SECURITY_NUMBER,
        placeholderTranslation: ETranslation.DEAL_USER_SOCIAL_SECURITY_NUMBER,
        value: "",
      },
      [EDealEdit.USER_STREET_ADDRESS]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_STREET_ADDRESS,
        placeholderTranslation: ETranslation.DEAL_USER_STREET_ADDRESS,
        value: "",
      },
      [EDealEdit.USER_CITY]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_CITY,
        placeholderTranslation: ETranslation.DEAL_USER_CITY,
        value: "",
      },
      [EDealEdit.USER_ZIP]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_USER_ZIP,
        placeholderTranslation: ETranslation.DEAL_USER_ZIP,
        value: "",
      },
      [EDealEdit.INVOICE_REFERENCE]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_INVOICE_REFERENCE,
        placeholderTranslation: ETranslation.DEAL_INVOICE_REFERENCE,
        value: "",
      },
      [EDealEdit.COST_CENTER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_COST_CENTER,
        placeholderTranslation: ETranslation.DEAL_COST_CENTER,
        value: "",
      },
      [EDealEdit.INVOICE_PAYER]: {
        type: EInputType.select,
        labelTranslation: ETranslation.DEAL_INVOICE_PAYER,
        placeholderTranslation: ETranslation.DEAL_INVOICE_PAYER,
        options: invoicePayerOptions,
        value: "",
      },
      [EDealEdit.FINANCE_COMPANY_CONTRACT_NUMBER]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_FINANCE_COMPANY_CONTRACT_NUMBER,
        placeholderTranslation: ETranslation.DEAL_FINANCE_COMPANY_CONTRACT_NUMBER,
        value: "",
      },
      [EDealEdit.hideVatPrices]: {
        type: EInputType.checkbox,
        labelTranslation: ETranslation.DEAL_HIDE_VAT_PRICES,
        value: false,
      },
      [EDealEdit.insuranceCertificateReceived]: {
        type: EInputType.checkbox,
        labelTranslation: ETranslation.DEAL_INSURANCE_CERTIFICATE_RECEIVED,
        value: false,
      },
      [EDealEdit.invoiceInfo]: {
        type: EInputType.textarea,
        labelTranslation: ETranslation.DEAL_INVOICE_INFO,
        placeholderTranslation: ETranslation.DEAL_INVOICE_INFO,
        value: "",
      },
      [EDealEdit.modelYear]: {
        type: EInputType.text,
        labelTranslation: ETranslation.DEAL_MODEL_YEAR,
        placeholderTranslation: ETranslation.DEAL_MODEL_YEAR,
        value: "",
      },
      [EDealEdit.maintenanceServices]: {
        type: EInputType.conditionList,
        value: maintenanceServices(t),
      },
      [EDealEdit.vatPercent]: {
        type: EInputType.select,
        labelTranslation: ETranslation.DEAL_VAT_PERCENT,
        placeholderTranslation: ETranslation.DEAL_VAT_PERCENT,
        value: "",
        options: VAT_PERCENT_OPTIONS,
        validation: {
          required: true,
        },
      },
      [EDealEdit.usedKilometers]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_CAR_USED_KILOMETERS,
        placeholderTranslation: ETranslation.DEAL_CAR_USED_KILOMETERS,
        post: InputSuffix.KILOMETERS,
        value: "",
      },
      [EDealEdit.usedHours]: {
        type: EInputType.number,
        labelTranslation: ETranslation.DEAL_MACHINE_USED_HOURS,
        placeholderTranslation: ETranslation.DEAL_MACHINE_USED_HOURS,
        post: InputSuffix.HOURS(t),
        value: "",
      },
    })
  }, []);

  const [inputs, setInputs] = useState<IInputField>(() => createInitialInputs(t));
  const [variations, setVariations] = useState<IDealPricingVariation[]>([defaultPricingVariation(generateUUID(EDealEdit.DEAL_PRICING_VARIATIONS))]);
  const [isValid, setValid] = useState<boolean>(true);
  const { setModal } = useContext(ModalContext);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [additionalExpenses, setAdditionalExpenses] = useState<IDealExtraFee[]>([]);

  useEffect(() => {
    // console.log("useEffect() fetch lists");
    dispatch(actions.listCustomers());
    dispatch(actions.listFinanceCompanies());
    dispatch(actions.listDealerships());
  }, [dispatch]);

  useEffect(() => {
    // console.log("useEffect() get/set deal", id);
    if (pipedriveDeal) {
      // console.log("redirected from pipedrive, deal id", pipedriveDeal.id);
      // console.log("pipedrive deal", pipedriveDeal);
      if (pipedriveDeal.id && pipedriveDeal.companyId) {
        // console.log("get existing deal");
        setView(stateView => {
          stateView[EDeal.VIEW].value = EDealView.PREVIEW;
          return stateView;
        });
        dispatch(actions.getPipedriveDeal(pipedriveDeal.id, pipedriveDeal.companyId));
      }
    } else {
      if (!isNew) {
        dispatch(actions.getDeal(id));
      } else {
        // reset inputs
        dispatch(actions.clearDeal());
        if (isPreoffer) {
          let preOfferId = id.replace("preoffer=", "")
          dispatch(actions.getPreoffer(preOfferId));
        } 

        const newView = { ...view };
        if (newView[EDeal.VIEW].value === EDealView.PREVIEW) {
          setView(stateView => {
            stateView[EDeal.VIEW].value = EDealView.EDIT;
            return stateView;
          });
        }
        setDisabled(false);
      }
    }
    // eslint-disable-next-line
  }, [id, dispatch, isNew]);

  const initDeal = useCallback((deal: IDeal, isPreOffer: boolean) => {
    setDisabled(!!deal?.importName || deal?.status === EDealStatus.CONTRACT || deal?.status === EDealStatus.TERMINATED || deal?.archived || false);
    if (deal.dealPricingVariations && deal.dealPricingVariations.length > 0) setVariations(deal.dealPricingVariations);
    setAdditionalExpenses(deal.additionalExpenses ?? []);
    if (!deal.basicServices) deal.basicServices = carBasicServices(t);
    if (!deal.additionalInformation) deal.additionalInformation = machineAdditionalInformation(t);
    if (!deal.maintenanceServices) deal.maintenanceServices = maintenanceServices(t);
    if (!deal.attachments) deal.attachments = [];

    const contactPersonName = deal?.customer?.contactPersonName;
    const contactPersonEmail = deal?.customer?.contactPersonEmail;
    const contactPersonPhoneNumber = deal?.customer?.contactPersonPhoneNumber;
    const financeCompany = financeCompanies && deal?.financeCompany ? financeCompanies.find(v => v.id === deal?.financeCompany?.id)?.id || "" : ''
    const dealership = dealerships && deal?.dealership ? dealerships.find(d => d.id === deal?.dealership?.id)?.id || "" : ''

    // console.log(!!contactPersonName, !!contactPersonEmail, !!contactPersonPhoneNumber, !!financeCompany, !!dealership);

    setInputs(stateInputs => {
      const newInputs = initForm(stateInputs, deal || defaultDeal(t));
      newInputs[EDealEdit.CUSTOMER].value = customers && deal?.customer ? customers.find(v => v.id === deal?.customer?.id)?.id || "" : '';
      newInputs[EDealEdit.CUSTOMER_NAME].value = deal?.customer?.name || '';
      newInputs[EDealEdit.CUSTOMER_BUSINESS_ID].value = deal?.customer?.businessId || '';
      newInputs[EDealEdit.CUSTOMER_STREET_ADDRESS].value = deal?.customer?.streetAddress || '';
      newInputs[EDealEdit.CUSTOMER_ZIP].value = deal?.customer?.zip || '';
      newInputs[EDealEdit.CUSTOMER_CITY].value = deal?.customer?.city || '';
      newInputs[EDealEdit.contactPersonName].value = contactPersonName || '';
      newInputs[EDealEdit.contactPersonEmail].value = contactPersonEmail || '';
      newInputs[EDealEdit.contactPersonPhoneNumber].value = contactPersonPhoneNumber || '';
      newInputs[EDealEdit.FINANCE_COMPANY].value = financeCompany;
      newInputs[EDealEdit.DEALERSHIP].value = dealership;
      newInputs[EDealEdit.USER_NAME].value = deal?.user?.name ? deal.user.name : '';
      newInputs[EDealEdit.USER_EMAIL].value = deal?.user?.email ? deal.user.email : '';
      newInputs[EDealEdit.USER_PHONE_NUMBER].value = deal?.user?.phoneNumber ? deal.user.phoneNumber : '';
      newInputs[EDealEdit.USER_SOCIAL_SECURITY_NUMBER].value = deal?.user?.socialSecurityNumber ? deal.user.socialSecurityNumber : '';
      newInputs[EDealEdit.USER_STREET_ADDRESS].value = deal?.user?.streetAddress ? deal.user.streetAddress : '';
      newInputs[EDealEdit.USER_CITY].value = deal?.user?.city ? deal.user.city : '';
      newInputs[EDealEdit.USER_ZIP].value = deal?.user?.zip ? deal.user.zip : '';
      if (isPreOffer) newInputs[EDealEdit.TYPE].value = EDealType.MACHINE;
      newInputs[EDealEdit.contactPersonName].validationResult = !!contactPersonName ? VALID_DEFAULT : INVALID_DEFAULT;
      newInputs[EDealEdit.contactPersonEmail].validationResult = !!contactPersonEmail && isValidEmail(contactPersonEmail) ? VALID_DEFAULT : { isValid: false, message: ETranslation.VALIDATION_EMAIL };
      newInputs[EDealEdit.contactPersonPhoneNumber].validationResult = !!contactPersonPhoneNumber ? VALID_DEFAULT : INVALID_DEFAULT;
      newInputs[EDealEdit.FINANCE_COMPANY].validationResult = !!financeCompany ? VALID_DEFAULT : INVALID_DEFAULT;
      newInputs[EDealEdit.DEALERSHIP].validationResult = !!dealership ? VALID_DEFAULT : INVALID_DEFAULT;
      return newInputs;
    });
  }, [customers, financeCompanies, dealerships, t]);

  useEffect(() => {
    // console.log("useEffect() deal changed:", deal)
    if (dealsLoading || customersLoading || financeCompaniesLoading || dealershipsLoading) return;
    if (!deal && !preOffer) {
      setInputs(stateInputs => {
        const newInputs = initForm(stateInputs, defaultDeal(t));
        if (pipedriveDeal && pipedriveDeal.organization) {
          const pipedriveOrganization = pipedriveDeal.organization;
          if (pipedriveOrganization.name) newInputs[EDealEdit.CUSTOMER_NAME].value = pipedriveOrganization.name;
          if (pipedriveOrganization.businessId) newInputs[EDealEdit.CUSTOMER_BUSINESS_ID].value = pipedriveOrganization.businessId;
          if (pipedriveOrganization.address) newInputs[EDealEdit.CUSTOMER_STREET_ADDRESS].value = pipedriveOrganization.address;
          if (pipedriveOrganization.zip) newInputs[EDealEdit.CUSTOMER_ZIP].value = pipedriveOrganization.zip;
          if (pipedriveOrganization.city) newInputs[EDealEdit.CUSTOMER_CITY].value = pipedriveOrganization.city;
        }
        return newInputs;
      });
      setVariations([defaultPricingVariation(generateUUID(EDealEdit.DEAL_PRICING_VARIATIONS))]);
      setAdditionalExpenses([]);
      setFinanceCompany(null);
      return;
    }
    if (preOffer) {
      initDeal(preOffer, true);
      return;
    }
    if (deal) {
      initDeal(deal, false);
    }
  }, [customersLoading, deal, dealershipsLoading, dealsLoading, financeCompaniesLoading, initDeal, pipedriveDeal, preOffer, t]);

  const mapList = (list: TListValue, inputName?: EDealEdit) => {
    return [{ value: "", labelTranslation: ETranslation.COMMON_NO_CHOICE },
    ...list.filter(value => {
      return typeof value.id !== "undefined" && typeof value.name !== "undefined";
    }).map(value => {
      if (!value.id || !value.name) return { value: "", name: "" };

      let label = "";
      if (value.name) label = value.name;

      if (inputName === EDealEdit.CUSTOMER) {
        const val = value as IOrganization;
        if (val.businessId) {
          label += ` (${val.businessId})`;
        }
      }

      return {
        value: value.id,
        label: label,
      };
    })];
  };

  const financeCompanyId = inputs[EDealEdit.FINANCE_COMPANY].value as string;
  useEffect(() => {
    if (financeCompanyId && financeCompanies) {
      setFinanceCompany(financeCompanies.find(c => c.id === financeCompanyId) || null);
    } else {
      setFinanceCompany(null);
    }
  }, [financeCompanyId, financeCompanies]);

  // set interest rate
  useEffect(() => {
    setInputs(stateInputs => {
      const newInputs = { ...stateInputs };
      const invoicingFee = newInputs[EDealEdit.INVOICING_FEE].value as string;
      if (financeCompany?.invoicingFee && (typeof invoicingFee === 'undefined' || !invoicingFee)) {
        newInputs[EDealEdit.INVOICING_FEE].value = financeCompany?.invoicingFee;
      }
      const openingFee = newInputs[EDealEdit.OPENING_FEE].value as string;
      if (financeCompany?.openingFee && (typeof openingFee === 'undefined' || !openingFee)) {
        newInputs[EDealEdit.OPENING_FEE].value = financeCompany?.openingFee;
      }
      return newInputs;
    });
  }, [financeCompany]);

  const customerId = inputs[EDealEdit.CUSTOMER].value as string;
  useEffect(() => {
    if (customerId && customers) {
      // console.log(customerId)
      setCustomer(customers.find(c => c.id === customerId) || null);
    } else {
      setCustomer(null);
    }
  }, [customerId, customers]);

  // update customer fields when customer changes
  useEffect(() => {
    // console.log("customer changed");
    const contactPersonName = customer?.contactPersonName;
    const contactPersonEmail = customer?.contactPersonEmail;
    const contactPersonPhoneNumber = customer?.contactPersonPhoneNumber;
    setInputs(stateInputs => {
      const newInputs = { ...stateInputs };
      newInputs[EDealEdit.CUSTOMER_NAME].value = customer?.name || '';
      newInputs[EDealEdit.CUSTOMER_BUSINESS_ID].value = customer?.businessId || '';
      newInputs[EDealEdit.CUSTOMER_STREET_ADDRESS].value = customer?.streetAddress || '';
      newInputs[EDealEdit.CUSTOMER_ZIP].value = customer?.zip || '';
      newInputs[EDealEdit.CUSTOMER_CITY].value = customer?.city || '';
      newInputs[EDealEdit.contactPersonName].value = contactPersonName || '';
      newInputs[EDealEdit.contactPersonEmail].value = contactPersonEmail || '';
      newInputs[EDealEdit.contactPersonPhoneNumber].value = contactPersonPhoneNumber || '';
      newInputs[EDealEdit.contactPersonName].validationResult = !!contactPersonName ? VALID_DEFAULT : INVALID_DEFAULT;
      newInputs[EDealEdit.contactPersonEmail].validationResult = !!contactPersonEmail && isValidEmail(contactPersonEmail) ? VALID_DEFAULT : { isValid: false, message: ETranslation.VALIDATION_EMAIL };
      newInputs[EDealEdit.contactPersonPhoneNumber].validationResult = !!contactPersonPhoneNumber ? VALID_DEFAULT : INVALID_DEFAULT;
      return newInputs;
    });
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    if (biltemaResponse) {
      setInputs(stateInputs => {
        const newInputs = { ...stateInputs };
        newInputs[EDealEdit.BRAND].value = biltemaResponse.brand;
        newInputs[EDealEdit.modelYear].value = biltemaResponse.modelYear;
        newInputs[EDealEdit.SERIAL_NUMBER].value = biltemaResponse.serialNumber;
        newInputs[EDealEdit.REGISTRATION_DATE].value = biltemaResponse.registrationDate;
        return newInputs;
      });
      dispatch(actions.clearBiltemaState());
    }
  }, [dispatch, biltemaResponse]);

  const setApiErrorModal = useCallback((text?: string) => setModal({
    isOpen: true,
    title: t(ETranslation.API_ERROR_TITLE),
    size: EModalSize.SMALL,
    content: text ?? t(ETranslation.API_ERROR_TEXT),
  }), [setModal, t]);

  useEffect(() => {
    if (biltemaError) dispatch(actions.clearBiltemaState());
    if (netwheelsError) dispatch(actions.clearNetwheelsState());
    if (biltemaError || netwheelsError) setApiErrorModal();
  }, [dispatch, setApiErrorModal, biltemaError, netwheelsError]);

  const updateDealStatus = (status: EDealStatus, date: Date, notes?: string, id?: string) => {
    if (!deal) return;

    const newDeal: IDeal = { ...getDealData(), id: deal.id, status: deal.status, financeCompany: deal.financeCompany };
    if (!newDeal.dealership?.id) newDeal.dealership = undefined;
    switch (status) {
      case EDealStatus.PRECONTRACT:
        if (!variations) return;
        const newVariations = [...variations];
        newVariations.forEach(v => {
          if (v.id !== id) {
            v.hidden = true;
          } else {
            v.hidden = false;
          }
        });
        setVariations(newVariations);

        newDeal.dealPricingVariations = newVariations;
        newDeal.oldStatus = newDeal.status;
        newDeal.status = status;
        if (date) newDeal.handoverDate = dateToString(date);
        if (notes) newDeal.handoverNotes = notes;
        if (authUser) newDeal.handoverUser = authUser;
        break;
      case EDealStatus.CONTRACT:
        newDeal.dealPricingVariations = deal.dealPricingVariations;
        if (date) newDeal.handoverDate = dateToString(date);
        newDeal.type = deal.type;
        newDeal.leasingModel = deal.leasingModel;
        newDeal.oldStatus = newDeal.status;
        newDeal.status = status;
        if (date) newDeal.confirmDate = dateToString(new Date());
        if (authUser) newDeal.confirmUser = authUser;
        break;
      case EDealStatus.TERMINATED:
        newDeal.dealPricingVariations = deal.dealPricingVariations;
        newDeal.oldStatus = newDeal.status;
        newDeal.status = status;
        if (date) newDeal.terminatedDate = dateToString(date);
        if (authUser) newDeal.terminatedUser = authUser;
        if (notes) newDeal.terminatedNotes = notes;
        break;
      default:
        break;
    }

    dispatch(actions.updateDealStatus(newDeal));
    // saveOrUpdateDeal(event, inputs, { status, date, notes });
    setModal(defaultModal);
  };

  const createInput = (inputName: EDeal, options?: IInputOptions) => {
    const item = view[inputName];
    return <Input
      disabled={false}
      {...item}
      {...options}
      updateAction={EInputUpdateAction.DEAL}
      onChange={value => updateInputHandler(inputName, value, setView)}
      inputName={inputName}
    />
  };

  const getDealData = (): IDeal => {
    const name = inputs[EDealEdit.NAME].value as string;
    const customerId = inputs[EDealEdit.CUSTOMER].value as string;

    const customerName = inputs[EDealEdit.CUSTOMER_NAME].value as string;
    const customerBusinessId = inputs[EDealEdit.CUSTOMER_BUSINESS_ID].value as string;
    const customerStreetAddress = inputs[EDealEdit.CUSTOMER_STREET_ADDRESS].value as string;
    const customerZip = inputs[EDealEdit.CUSTOMER_ZIP].value as string;
    const customerCity = inputs[EDealEdit.CUSTOMER_CITY].value as string;
    const contactPersonName = inputs[EDealEdit.contactPersonName].value as string;
    const contactPersonEmail = inputs[EDealEdit.contactPersonEmail].value as string;
    const contactPersonPhoneNumber = inputs[EDealEdit.contactPersonPhoneNumber].value as string;

    const type = inputs[EDealEdit.TYPE].value as EDealType;
    const leasingModel = inputs[EDealEdit.LEASING_MODEL].value as ELeasingModel;

    const dealershipOption = inputs[EDealEdit.DEALERSHIP].value as string;
    let dealership: IDealership | undefined = undefined;
    if (dealerships) dealership = dealerships.find(c => c.id === dealershipOption) || undefined;
    if (!dealership) {
      dealership = {
        id: "",
        name: dealershipOption
      };
    }

    const dealershipSellerName = inputs[EDealEdit.DEALERSHIP_SELLER_NAME].value as string;
    const dealershipSaleNumber = inputs[EDealEdit.DEALERSHIP_SALE_NUMBER].value as string;

    const brand = inputs[EDealEdit.BRAND].value as string;
    const model = inputs[EDealEdit.MODEL].value as string;
    const co2Emissions = inputs[EDealEdit.CO2_EMISSIONS].value as number;
    const takeHomeVehicle = inputs[EDealEdit.TAKE_HOME_VEHICLE].value as number;
    const carBenefit = inputs[EDealEdit.CAR_BENEFIT].value as number;
    const registrationNumber = inputs[EDealEdit.REGISTRATION_NUMBER].value as string;

    const registrationDate = inputs[EDealEdit.REGISTRATION_DATE].value as Date;
    const signingDate = inputs[EDealEdit.SIGNING_DATE].value as Date;
    const surety = inputs[EDealEdit.SURETY].value as string;
    const handoverDate = inputs[EDealEdit.HANDOVER_DATE].value as Date;

    const insurance = inputs[EDealEdit.INSURANCE].value as string;
    const accessories = inputs[EDealEdit.ACCESSORIES].value as string;
    const accessories2 = inputs[EDealEdit.ACCESSORIES2].value as string;

    const totalPrice = inputs[EDealEdit.TOTAL_PRICE].value as number;
    const discount = inputs[EDealEdit.DISCOUNT].value as number;
    const carTax = inputs[EDealEdit.CAR_TAX].value as number;

    const totalPriceVAT = inputs[EDealEdit.TOTAL_PRICE_VAT].value as number;

    const additionalInformation = inputs[EDealEdit.ADDITIONAL_INFORMATION].value as ICondition[];

    const financeCompanyId = inputs[EDealEdit.FINANCE_COMPANY].value as string;

    const attachments = inputs[EDealEdit.ATTACHMENTS].value as IAttachment[];

    let customer: IOrganization | undefined = undefined;
    if (customers) customer = customers.find(c => c.id === customerId) || undefined;

    if (customerName) customer = { id: "", ...customer, name: customerName };
    if (customerBusinessId) customer = { id: "", ...customer, businessId: customerBusinessId };
    if (customerStreetAddress) customer = { id: "", ...customer, streetAddress: customerStreetAddress };
    if (customerZip) customer = { id: "", ...customer, zip: customerZip };
    if (customerCity) customer = { id: "", ...customer, city: customerCity };
    if (contactPersonName) customer = { id: "", ...customer, contactPersonName };
    if (contactPersonEmail) customer = { id: "", ...customer, contactPersonEmail };
    if (contactPersonPhoneNumber) customer = { id: "", ...customer, contactPersonPhoneNumber };
    

    let financeCompany: IFinanceCompany | undefined = undefined;
    if (financeCompanies) financeCompany = financeCompanies.find(c => c.id === financeCompanyId) || undefined;

    const serialNumber = inputs[EDealEdit.SERIAL_NUMBER].value as string;

    const user: IDealUser = {
      name: inputs[EDealEdit.USER_NAME].value as string,
      email: inputs[EDealEdit.USER_EMAIL].value as string,
      phoneNumber: inputs[EDealEdit.USER_PHONE_NUMBER].value as string,
      socialSecurityNumber: inputs[EDealEdit.USER_SOCIAL_SECURITY_NUMBER].value as string,
      streetAddress: inputs[EDealEdit.USER_STREET_ADDRESS].value as string,
      city: inputs[EDealEdit.USER_CITY].value as string,
      zip: inputs[EDealEdit.USER_ZIP].value as string,
    }

    const invoiceReference = inputs[EDealEdit.INVOICE_REFERENCE].value as string;
    const costCenter = inputs[EDealEdit.COST_CENTER].value as string;
    const invoicePayer = inputs[EDealEdit.INVOICE_PAYER].value as EInvoicePayer;

    const selectedVariation = variations?.find(v => !v.hidden);

    const basicServices = inputs[EDealEdit.BASIC_SERVICES].value as ICondition[];
    const invoicingFee = inputs[EDealEdit.INVOICING_FEE].value as number;
    const openingFee = inputs[EDealEdit.OPENING_FEE].value as number;

    const financeCompanyContractNumber = inputs[EDealEdit.FINANCE_COMPANY_CONTRACT_NUMBER].value as string;
    const hideVatPrices = inputs[EDealEdit.hideVatPrices].value as boolean;
    const insuranceCertificateReceived = inputs[EDealEdit.insuranceCertificateReceived].value as boolean;
    const invoiceInfo = inputs[EDealEdit.invoiceInfo].value as string;

    const modelYear = inputs[EDealEdit.modelYear].value as string;

    const maintenanceServices = inputs[EDealEdit.maintenanceServices].value as ICondition[];

    const vatPercent = inputs[EDealEdit.vatPercent].value as number;

    const usedKilometers = inputs[EDealEdit.usedKilometers].value as number;
    const usedHours = inputs[EDealEdit.usedHours].value as number;

    const newDeal: IDeal = {
      id: "",
      ...deal,
      name, customer, type, leasingModel,
      dealership, dealershipSellerName, dealershipSaleNumber,
      brand, model,
      registrationNumber, registrationDate: dateToString(registrationDate),
      signingDate: dateToString(signingDate), surety, handoverDate: dateToString(handoverDate),
      insurance, accessories, accessories2,
      dealPricingVariations: variations,
      basicServices, financeCompany, attachments,
      serialNumber, user, invoiceReference,
      co2Emissions, takeHomeVehicle, carBenefit,
      totalPriceVAT, discount, carTax,
      totalPrice, additionalInformation,
      selectedVariation, costCenter, invoicePayer, invoicingFee, openingFee,
      financeCompanyContractNumber, hideVatPrices, insuranceCertificateReceived,
      additionalExpenses, invoiceInfo, modelYear, maintenanceServices,
      seller: preOffer?.seller ?? deal?.seller ?? undefined,
      partnerUser: preOffer?.partnerUser ?? deal?.partnerUser ?? undefined,
      preofferOriginId: preOffer?.id ?? deal?.preofferOriginId ?? undefined,
      preofferSendDate: preOffer?.sendDate ?? undefined,
      partnerContractLength: preOffer?.partnerContractLength ?? undefined,
      partnerExchangeValue: preOffer?.partnerExchangeValue ?? undefined,
      partnerExchangeDescription: preOffer?.partnerExchangeDescription ?? undefined,
      partnerResidual: preOffer?.partnerResidual ?? undefined,
      vatPercent, usedKilometers, usedHours
    }

    if (pipedriveDeal) {
      if (pipedriveDeal.id) newDeal.pipedriveDealId = pipedriveDeal.id;
      if (pipedriveDeal?.organization?.companyId) {
        newDeal.pipedriveOrganizationId = pipedriveDeal.organization.companyId;
        if (customer) customer.pipedriveOrganizationId = pipedriveDeal.organization.companyId;
      }
      if (pipedriveDeal.companyId) newDeal.pipedriveCompanyId = pipedriveDeal.companyId;
    }

    // console.log("get deal data", totalPrice, newDeal.dealPricingVariations);
    return newDeal;
  }

  const saveOrUpdateDeal = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, inputs: IInputField, update?: IDealUpdate) => {
    event.preventDefault();
    const newDeal = getDealData();

    let dbDeal: IDeal | null = null;

    if (isNew && !deal?.id) {
      dbDeal = await dispatch(actions.saveDeal(newDeal));
      // setView(stateView => {
      //   stateView[EDeal.VIEW].value = EDealView.PREVIEW;
      //   return stateView;
      // });
      if (!newDeal.dealership?.id && dbDeal?.dealership?.id) {
        dispatch(actions.updateDealershipList(dbDeal.dealership));
      }
    } else {
      const dbDeal2 = await dispatch(actions.updateDeal(newDeal));
      // update dealership to state
      if (!newDeal.dealership?.id && dbDeal2?.dealership?.id) {
        dispatch(actions.updateDealershipList(dbDeal2.dealership));
      }
    }

    // console.log(newDeal);
    // clear attachments
    dispatch(actions.clearAttachmentState());
    // after save, redirect to saved deal
    if (dbDeal && dbDeal.id) history.push(Routes.DEAL(dbDeal.id));
  };

  const copyDeal = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const newDeal = getDealData();
    let dbDeal: IDeal | null = await dispatch(actions.copyDeal(newDeal));
    dispatch(actions.clearAttachmentState());
    if (dbDeal && dbDeal.id) history.push(Routes.DEAL(dbDeal?.id));
    if (isDisabled) {
      setDisabled(false);
    }
    setView(stateView => {
      stateView[EDeal.VIEW].value = EDealView.EDIT;
      return stateView;
    });
  };

  // const updateVariation = (id: string, field: string, value: TInputValue) => {
  //   // console.log("Updating pricing variation with id", id, field, value);
  //   setVariations(variations.map(el => el.id === id ? { ...el, [field]: value } : el));
  // };

  const updateVariation = (variation: IDealPricingVariation) => {
    // console.log("Updating pricing variation", variation, inputs[EDealEdit.TOTAL_PRICE].value);
    setVariations(stateVariations => {
      const newVariations = [...stateVariations].map(el => el.id === variation.id ? { ...variation } : el)
      return newVariations;
    });
  };

  const removeVariation = (id: string) => {
    // console.log("Removing pricing variation with id", pricingVariation.id, pricingVariation);
    setVariations(stateVariations => {
      let newVariations = [...stateVariations];
      newVariations = newVariations.filter(v => v.id !== id);
      if (newVariations.length === 0) {
        newVariations.push(defaultPricingVariation(generateUUID(EDealEdit.DEAL_PRICING_VARIATIONS)));
      }
      return newVariations;
    });
  };

  const copyVariationExtraFees = (fees?: IDealExtraFee[]) => {
    if (!fees) return [];
    const newFees = [...fees];
    newFees.forEach(fee => fee.id = generateUUID(EDealVariation.EXTRA_FEES));
    return newFees;
  }

  const copyVariation = (pricingVariation: IDealPricingVariation) => {
    // console.log("Copying pricing variation with id", pricingVariation.id, pricingVariation);
    // console.log(newVariations)
    setVariations(stateVariations => {
      const newVariations = [...stateVariations];
      newVariations.push({ ...pricingVariation, id: generateUUID(EDealEdit.DEAL_PRICING_VARIATIONS), extraFees: copyVariationExtraFees(pricingVariation.extraFees) });
      return newVariations;
    });
  };

  const setDealHandoverDialog = (variation: IDealPricingVariation) => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_PREVIEW_CREATE_SUBSCRIPTION_AGREEMENT),
    size: EModalSize.SMALL,
    content: (
      <DealHandoverDialog
        id={variation.id}
        handoverDate={inputs[EDealEdit.HANDOVER_DATE].value as Date}
        updateDealStatus={updateDealStatus}
        updateStatus={EDealStatus.PRECONTRACT}
      />
    ),
  });

  const registrationNumber = inputs[EDealEdit.REGISTRATION_NUMBER].value as string;
  const serialNumber = inputs[EDealEdit.SERIAL_NUMBER].value as string;
  const financeCompanyContractNumber = inputs[EDealEdit.FINANCE_COMPANY_CONTRACT_NUMBER].value as string;
  const insuranceCertificateReceived = inputs[EDealEdit.insuranceCertificateReceived].value as string;
  const type = inputs[EDealEdit.TYPE].value as EDealType;

  const getDisabledText = () => {
    const missing = [];

    if (type === EDealType.CAR && !registrationNumber) missing.push(t(ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_REGISTRATION_NUMBER));
    if (type === EDealType.MACHINE && !serialNumber) missing.push(t(ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_SERIAL_NUMBER));
    if (!financeCompanyContractNumber) missing.push(t(ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_FINANCE_COMPANY_CONTRACT_NUMBER));
    if (!insuranceCertificateReceived) missing.push(t(ETranslation.DEAL_PREVIEW_CONFIRM_MISSING_INSURANCE_CERTIFICATE_RECEIVED));

    return missing.length ? missing : undefined;
  }

  const setDealConfirmModal = () => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TITLE),
    size: EModalSize.SMALL,
    content: (
      <DealHandoverDialog
        handoverDate={inputs[EDealEdit.HANDOVER_DATE].value as Date}
        updateDealStatus={updateDealStatus}
        updateStatus={EDealStatus.CONTRACT}
        disabledText={((type === EDealType.CAR && !registrationNumber) 
          || (type === EDealType.MACHINE && !serialNumber)
          || !financeCompanyContractNumber
          || !insuranceCertificateReceived)
          ? getDisabledText() : undefined}
      />
      // <div>
      //   <p>{t(ETranslation.DEAL_PREVIEW_CONFIRM_DEAL_TEXT)}</p>
      //   <InputGroup>
      //     <Button onClick={() => updateDealStatus(EDealStatus.CONTRACT, new Date())}>{t(ETranslation.COMMON_YES)}</Button>
      //     <Button onClick={() => setModal(defaultModal)}>{t(ETranslation.COMMON_NO)}</Button>
      //   </InputGroup>
      // </div>
    ),
  });

  const setDealSendDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_SEND_TITLE),
    size: EModalSize.SMALL,
    content: (
      <DealSendDialog sendDeal={sendDeal} closeDialog={() => setModal(defaultModal)} />
    ),
  });

  const setDealTerminateDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_TERMINATE_DIALOG_TITLE),
    size: EModalSize.SMALL,
    content: (
      <DealTerminateDialog updateStatus={EDealStatus.TERMINATED} updateDealStatus={updateDealStatus} />
    ),
  });

  const showDealMaintenanceInfoDialog = (link: string) => setModal({
    isOpen: true,
    title: t(ETranslation.DEAL_PRINT_MAINTENANCE_INFO_TITLE),
    size: EModalSize.SMALL,
    content: (
      <DealMaintenanceInfoDialog link={link} />
    ),
  });

  const sendDeal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, recipients: string[]) => {
    event.preventDefault();
    if (!deal || !deal.id || !recipients || recipients.length === 0) return;
    console.log("send deal to", deal.id, recipients);
    dispatch(actions.sendDeal(deal.id, recipients));
    setModal(defaultModal);
    // history.push(Routes.DEALS);
  };

  const archiveHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (deal?.id) {
      dispatch(actions.archiveDeal(deal?.id));
      if (deal?.status !== EDealStatus.CONTRACT && deal?.status !== EDealStatus.TERMINATED && isDisabled && deal?.archived) {
        setDisabled(false);
      }
    }
  }

  const biltemaHandler = useCallback((registrationNumber: string) => {
    if (!registrationNumber) {
      setApiErrorModal(t(ETranslation.API_ERROR_REQUIRED_FIELDS_MISSING_BILTEMA));
      return;
    }
    dispatch(actions.getBiltemaExtendedInfo(registrationNumber));
  }, [dispatch, setApiErrorModal, t]);

  const netwheelsHandler = useCallback((request: IResidualValueRequest) => {
    if (
      !request ||
      !request.modelYear ||
      !request.make ||
      !request.model ||
      !request.listPriceIncludingVehicleTax ||
      !request.residualValuePredictionPeriods ||
      !request.residualValuePredictionPeriods[0].mileageDuringOwnership ||
      !request.residualValuePredictionPeriods[0].ownershipMonths
    ) {
      setApiErrorModal(t(ETranslation.API_ERROR_REQUIRED_FIELDS_MISSING_NETWHEELS));
      return;
    }
    dispatch(actions.getNetwheelsResidualValue(request));
  }, [dispatch, setApiErrorModal, t]);

  const getDealTitle = () => {
    switch (deal?.status) {
      case EDealStatus.OFFER:
        return t(ETranslation.TITLE_DEAL);
      case EDealStatus.PRECONTRACT:
        return t(ETranslation.TITLE_PRECONTRACT);
      case EDealStatus.CONTRACT:
        return t(ETranslation.TITLE_CONTRACT);
      case EDealStatus.TERMINATED:
        return t(ETranslation.TITLE_TERMINATED);
      default:
        return t(ETranslation.TITLE_DEAL_NEW);
    }
  }

  const updateExtraFee = (fee: IDealExtraFee, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => {
    // console.log("Updating extra fee", fee);
    setExtraFees(stateExtraFees => {
      const newExtraFees = [...stateExtraFees].map(el => el.id === fee.id ? { ...fee } : el);
      return newExtraFees;
    });
  };

  const addExtraFee = (inputName: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => {
    setExtraFees(stateExtraFees => {
      const newExtraFees = [...stateExtraFees, defaultExtraFee(generateUUID(inputName))];
      return newExtraFees;
    });
  };

  const removeExtraFee = (id: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => {
    // console.log("Removing pricing variation with id", pricingVariation.id, pricingVariation);
    setExtraFees(stateExtraFees => {
      const newExtraFees = [...stateExtraFees].filter(f => f.id !== id);
      return newExtraFees;
    });
  };

  const copyExtraFee = (fee: IDealExtraFee, inputName: string, setExtraFees: React.Dispatch<React.SetStateAction<IDealExtraFee[]>>) => {
    // console.log("Copying pricing variation with id", pricingVariation.id, pricingVariation);
    setExtraFees(stateExtraFees => {
      const newExtraFees = [...stateExtraFees];
      newExtraFees.push({ ...fee, id: generateUUID(inputName) });
      return newExtraFees;
    });
  };

  const dealView = view[EDeal.VIEW].value as EDealView;
  const isEdit = dealView === EDealView.EDIT;
  const isPreview = dealView === EDealView.PREVIEW;
  const isTerminate = dealView === EDealView.TERMINATE;

  return (
    <>
      {dealsError && <Alert>{t(dealsError)}</Alert>}
      {customersError && <Alert>{t(customersError)}</Alert>}
      {financeCompaniesError && <Alert>{t(financeCompaniesError)}</Alert>}
      {dealershipsError && <Alert>{t(dealershipsError)}</Alert>}
      {/* {biltemaError && <Alert>{t(biltemaError)}</Alert>}
      {netwheelsError && <Alert>{t(netwheelsError)}</Alert>} */}
      {/* {invoicesError && <Alert>{t(invoicesError)}</Alert>} */}
      {dealsError ? <></> :
      <div className={classes.Container}>
        {dealsLoading || customersLoading || financeCompaniesLoading || dealershipsLoading
          ? <Spinner />
          : (
            <>
              <h2>{deal?.archived && `${t(ETranslation.DEAL_ARCHIVED_DATE)} `}{getDealTitle()}</h2>
              {createInput(EDeal.VIEW, {options: (deal?.status === EDealStatus.CONTRACT || deal?.status === EDealStatus.TERMINATED) ? contractDealViewOptions : dealViewOptions })}
              <DealContext.Provider value={{
                id, saveOrUpdateDeal, inputs, setInputs,
                currentUser: authUser, variations, setVariations, deal,
                goBack: () => history.goBack(),
                updateVariation, removeVariation, copyVariation,
                isValid, setValid,
                setDealHandoverDialog, setDealConfirmModal, setDealSendDialog,
                isDisabled, getDealData, copyDeal, archiveDeal: archiveHandler,
                customerOptions: customers ? mapList(customers, EDealEdit.CUSTOMER) : [],
                financeCompanyOptions: financeCompanies ? mapList(financeCompanies) : [],
                dealershipOptions: dealerships ? mapList(dealerships) : [],
                financeCompany, setDealTerminateDialog,
                updateExtraFee, addExtraFee, removeExtraFee, copyExtraFee,
                additionalExpenses, setAdditionalExpenses,
                showDealMaintenanceInfoDialog: type === EDealType.CAR ? showDealMaintenanceInfoDialog : undefined,
                biltemaHandler, netwheelsHandler,
              }}>
                {isEdit && (
                  <DealEdit />
                )}
                {isPreview && (
                  <DealPreview history={history} match={match} location={location} />
                )}
                {isTerminate && (
                  <DealTerminate />
                )}
                {!isNew && authUser?.isPrimary && <DealNotes />}

                {!isNew && id &&
                <DealLog id={id}/>
                }

              </DealContext.Provider>
            </>
          )
        }
      </div>
      }
    </>
  );
};

export default Deal;
