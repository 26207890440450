export enum ETranslation {
  // Common
  COMMON_SAVE = "COMMON_SAVE",
  COMMON_RETURN = "COMMMON_RETURN",
  COMMON_ADD = "COMMON_ADD",
  COMMON_REMOVE = "COMMON_REMOVE",
  COMMON_COPY = "COMMON_COPY",
  COMMON_STREET_ADDRESS = "COMMON_STREET_ADDRESS",
  COMMON_ZIP = "COMMON_ZIP",
  COMMON_CITY = "COMMON_CITY",
  COMMON_NO_CHOICE = "COMMON_NO_CHOICE",
  COMMON_CREATED = "COMMON_CREATED",
  COMMON_YES = "COMMON_YES",
  COMMON_NO = "COMMON_NO",
  COMMON_YEAR = "COMMON_YEAR",
  COMMON_OK = "COMMON_OK",
  COMMON_DELETE = "COMMON_DELETE",
  COMMON_SEND = "COMMON_SEND",

  // Auth
  TITLE_LEASING_MANAGEMENT = "TITLE_LEASING_MANAGEMENT",
  LOGIN = "LOGIN",

  // Auth Inputs
  USERNAME = "USERNAME",
  PASSWORD = "PASSWORD",
  PASSWORD_MIN_LENGTH_MESSAGE = "PASSWORD_MIN_LENGTH_MESSAGE",

  // Header
  DEALS = "DEALS",
  DEALS_NEW = "DEALS_NEW",
  REPORTS = "REPORTS",
  CUSTOMERS = "CUSTOMERS",
  PARTNERS = "PARTNERS",
  SETTINGS = "SETTINGS",
  LOGOUT = "LOGOUT",
  USERS = "USERS",
  DASHBOARD = "DASHBOARD",
  INVOICES = "INVOICES",
  DRAFTS = "DRAFTS",
  PREOFFERS = "PREOFFERS",
  CONTACTS = "CONTACTS",
  LOG = "LOG",

  REPORTS_OFFER = "REPORTS_OFFER",
  REPORTS_PRECONTRACT = "REPORTS_PRECONTRACT",
  REPORTS_CONTRACT = "REPORTS_CONTRACT",
  REPORTS_TERMINATED = "REPORTS_TERMINATED",
  REPORTS_FEES = "REPORTS_FEES",
  REPORTS_MAINTENANCE_BUDGET = "REPORTS_MAINTENANCE_BUDGET",
  REPORTS_MAINTENANCE_LEASING = "REPORTS_MAINTENANCE_LEASING",
  REPORTS_ARCHIVED = "REPORTS_ARCHIVED",

  SEARCH = "SEARCH",

  // Deals
  TITLE_DEALS = "TITLE_DEALS",
  DEALS_ADD_NEW = "DEALS_ADD_NEW",
  NO_DEALS = "NO_DEALS",
  DEALS_FETCH_ALL = "DEALS_FETCH_ALL",

  // Deal statuses
  DEAL_STATUS_OFFER = "DEAL_STATUS_OFFER",
  DEAL_STATUS_PRECONTRACT = "DEAL_STATUS_PRECONTRACT",
  DEAL_STATUS_CONTRACT = "DEAL_STATUS_CONTRACT",
  DEAL_STATUS_TERMINATED = "DEAL_STATUS_TERMINATED",
  DEAL_STATUS_ARCHIVED = "DEAL_STATUS_ARCHIVED",
  DEAL_STATUS_DRAFT = "DEAL_STATUS_DRAFT",
  DEAL_STATUS_PREOFFER = "DEAL_STATUS_PREOFFER",

  // Deal
  DEAL_VIEW_EDIT = "DEAL_VIEW_EDIT",
  DEAL_VIEW_PREVIEW = "DEAL_VIEW_PREVIEW",
  DEAL_VIEW_TERMINATE = "DEAL_VIEW_TERMINATE",

  // DealPreview
  DEAL_PREVIEW_INFO_TEXT_1 = "DEAL_PREVIEW_INFO_TEXT_1",
  DEAL_PREVIEW_INFO_TEXT_2 = "DEAL_PREVIEW_INFO_TEXT_2",
  DEAL_PREVIEW_INFO_TEXT_3 = "DEAL_PREVIEW_INFO_TEXT_3",

  DEAL_PREVIEW_STARTS = "DEAL_PREVIEW_STARTS",
  DEAL_PREVIEW_ENDS = "DEAL_PREVIEW_ENDS",
  DEAL_PREVIEW_TERMINATED = "DEAL_PREVIEW_TERMINATED",
  DEAL_PREVIEW_TERMINATED_CHOSEN = "DEAL_PREVIEW_TERMINATED_CHOSEN",

  DEAL_PREVIEW_TITLE_BASIC_SERVICES = "DEAL_PREVIEW_TITLE_BASIC_SERVICES",
  DEAL_PREVIEW_TITLE_MAINTENANCE_SERVICES = "DEAL_PREVIEW_TITLE_MAINTENANCE_SERVICES",
  DEAL_PREVIEW_TITLE_ADDITIONAL_INFORMATION = "DEAL_PREVIEW_TITLE_ADDITIONAL_INFORMATION",

  DEAL_PREVIEW_REILU_LEASING_TITLE = "DEAL_PREVIEW_REILU_LEASING_TITLE",
  DEAL_PREVIEW_REILU_LEASING_TEXT = "DEAL_PREVIEW_REILU_LEASING_TEXT",
  DEAL_PREVIEW_HUOLTO_LEASING_TITLE = "DEAL_PREVIEW_HUOLTO_LEASING_TITLE",

  DEAL_PREVIEW_FOOTER_TEXT_1 = "DEAL_PREVIEW_FOOTER_TEXT_1",
  DEAL_PREVIEW_FOOTER_TEXT_2 = "DEAL_PREVIEW_FOOTER_TEXT_2",
  DEAL_PREVIEW_FOOTER_TEXT_3 = "DEAL_PREVIEW_FOOTER_TEXT_3",
  DEAL_PREVIEW_FOOTER_TEXT_4 = "DEAL_PREVIEW_FOOTER_TEXT_4",

  DEAL_PREVIEW_FOOTER_TEXT_5 = "DEAL_PREVIEW_FOOTER_TEXT_5",
  DEAL_PREVIEW_FOOTER_TEXT_5_1 = "DEAL_PREVIEW_FOOTER_TEXT_5_1",
  DEAL_PREVIEW_FOOTER_TEXT_5_2 = "DEAL_PREVIEW_FOOTER_TEXT_5_2",
  DEAL_PREVIEW_FOOTER_TEXT_5_3 = "DEAL_PREVIEW_FOOTER_TEXT_5_3",
  DEAL_PREVIEW_FOOTER_TEXT_5_4 = "DEAL_PREVIEW_FOOTER_TEXT_5_4",
  DEAL_PREVIEW_FOOTER_TEXT_5_5 = "DEAL_PREVIEW_FOOTER_TEXT_5_5",
  DEAL_PREVIEW_FOOTER_TEXT_5_6 = "DEAL_PREVIEW_FOOTER_TEXT_5_6",
  DEAL_PREVIEW_FOOTER_TEXT_5_7 = "DEAL_PREVIEW_FOOTER_TEXT_5_7",
  DEAL_PREVIEW_FOOTER_TEXT_5_8 = "DEAL_PREVIEW_FOOTER_TEXT_5_8",

  DEAL_PREVIEW_INVOICE_PROCESSING_FEE = "DEAL_PREVIEW_INVOICE_PROCESSING_FEE",
  DEAL_PREVIEW_SETUP_COST = "DEAL_PREVIEW_SETUP_COST",
  DEAL_PREVIEW_INVOICE_PROCESSING_FEE_VAT = "DEAL_PREVIEW_INVOICE_PROCESSING_FEE_VAT",
  DEAL_PREVIEW_SETUP_COST_VAT = "DEAL_PREVIEW_SETUP_COST_VAT",
  DEAL_PREVIEW_HELP_TEXT_SIGNATURE = "DEAL_PREVIEW_HELP_TEXT_SIGNATURE",

  DEAL_PREVIEW_BEST_REGARDS_TEXT = "DEAL_PREVIEW_BEST_REGARDS_TEXT",

  DEAL_PREVIEW_CREATE_SUBSCRIPTION_AGREEMENT = "DEAL_PREVIEW_CREATE_SUBSCRIPTION_AGREEMENT",
  DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TITLE = "DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TITLE",
  DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TEXT = "DEAL_PREVIEW_SUBSCRIPTION_AGREEMENT_TEXT",

  DEAL_PREVIEW_CONFIRM_DEAL_TITLE = "DEAL_PREVIEW_CONFIRM_DEAL_TITLE",
  DEAL_PREVIEW_CONFIRM_DEAL_TEXT = "DEAL_PREVIEW_CONFIRM_DEAL_TEXT",

  DEAL_PREVIEW_CONFIRM_MISSING_REGISTRATION_NUMBER = "DEAL_PREVIEW_CONFIRM_MISSING_REGISTRATION_NUMBER",
  DEAL_PREVIEW_CONFIRM_MISSING_SERIAL_NUMBER = "DEAL_PREVIEW_CONFIRM_MISSING_SERIAL_NUMBER",
  DEAL_PREVIEW_CONFIRM_MISSING_FINANCE_COMPANY_CONTRACT_NUMBER = "DEAL_PREVIEW_CONFIRM_MISSING_FINANCE_COMPANY_CONTRACT_NUMBER",
  DEAL_PREVIEW_CONFIRM_MISSING_INSURANCE_CERTIFICATE_RECEIVED = "DEAL_PREVIEW_CONFIRM_MISSING_INSURANCE_CERTIFICATE_RECEIVED",

  DEAL_PREVIEW_OFFER_ACCEPTED = "DEAL_PREVIEW_OFFER_ACCEPTED",
  DEAL_PREVIEW_OFFER_SIGNED_AT = "DEAL_PREVIEW_OFFER_SIGNED_AT",
  DEAL_PREVIEW_OFFER_SIGNATURE = "DEAL_PREVIEW_OFFER_SIGNATURE",
  DEAL_PREVIEW_OFFER_IDENTIFIED_BANK = "DEAL_PREVIEW_OFFER_IDENTIFIED_BANK",

  DEAL_TERMINATE_DIALOG_TITLE = "DEAL_TERMINATE_DIALOG_TITLE",
  DEAL_TERMINATE_DIALOG_TEXT = "DEAL_TERMINATE_DIALOG_TEXT",

  DEAL_PREVIEW_LINK_COPIED_TEXT = "DEAL_PREVIEW_LINK_COPIED_TEXT",

  // DealEdit
  TITLE_DEAL = "TITLE_DEAL",
  TITLE_DEAL_NEW = "TITLE_DEAL_NEW",
  TITLE_PRECONTRACT = "TITLE_PRECONTRACT",
  TITLE_CONTRACT = "TITLE_CONTRACT",
  TITLE_TERMINATED = "TITLE_TERMINATED",
  DEAL_NAME = "DEAL_NAME",
  DEAL_CUSTOMER = "customer",
  DEAL_SELLER = "seller",
  DEAL_SEND_DATE = "sendDate",
  DEAL_OPENED_DATE = "openedDate",
  DEAL_STATUS = "status",
  DEAL_ARCHIVED_DATE = "archivedDate",
  DEAL_ARCHIVED_USER = "archivedUser",
  DEAL_TYPE = "type",
  DEAL_INVOICE_REFERENCE = "invoiceReference",
  DEAL_COST_CENTER = "costCenter",
  DEAL_INVOICE_PAYER = "invoicePayer",
  DEAL_FINANCE_COMPANY_CONTRACT_NUMBER = "financeCompanyContractNumber",
  DEAL_HIDE_VAT_PRICES = "hideVatPrices",
  DEAL_INSURANCE_CERTIFICATE_RECEIVED = "insuranceCertificateReceived",

  INVOICE_PAYER_OLF = "INVOICE_PAYER_OLF",
  INVOICE_PAYER_FINANCE_COMPANY = "INVOICE_PAYER_FINANCE_COMPANY",

  // Deal types
  DEAL_TYPE_CAR = "DEAL_TYPE_CAR",
  DEAL_TYPE_MACHINE = "DEAL_TYPE_MACHINE",

  // myyjäliikkeeseen liittyvät kentät, lisää 'auto' eteen jos auto
  DEAL_CAR_DEALERSHIP = "DEAL_CAR_DEALERSHIP",
  DEAL_CAR_DEALERSHIP_SELLER_NAME = "DEAL_CAR_DEALERSHIP_SELLER_NAME",
  DEAL_CAR_DEALERSHIP_SALE_NUMBER = "DEAL_CAR_DEALERSHIP_SALE_NUMBER",

  DEAL_MACHINE_DEALERSHIP = "Dealership",
  DEAL_MACHINE_DEALERSHIP_SELLER_NAME = "dealershipSellerName",
  DEAL_MACHINE_DEALERSHIP_SALE_NUMBER = "dealershipSaleNumber",

  // koneeseen/autoon liittyvät kentät
  // koneen/auton merkki/nimi
  DEAL_BRAND = "brand",
  DEAL_CAR_BRAND = "DEAL_CAR_BRAND",
  DEAL_MODEL = "model",
  DEAL_MODEL_YEAR = "modelYear",
  DEAL_CAR_CO2_EMISSIONS = "co2Emissions",

  DEAL_MACHINE_BRAND = "DEAL_MACHINE_BRAND",

  // autoetu/käyttöetu?
  DEAL_CAR_TAKE_HOME_VEHICLE = "takeHomeVehicle",
  DEAL_CAR_CAR_BENEFIT = "carBenefit",

  DEAL_REGISTRATION_NUMBER = "registrationNumber",

  // rekisteröintiin liittyvät kentät
  DEAL_REGISTRATION_DATE = "registrationDate",
  DEAL_SIGNING_DATE = "signingDate",
  DEAL_SURETY = "surety",
  DEAL_HANDOVER_DATE = "handoverDate",
  DEAL_HANDOVER_NOTES = "handoverNotes",

  // terminate / lopetus
  DEAL_TERMINATED_DATE = "terminatedDate",
  DEAL_TERMINATED_USER = "terminatedUser",
  DEAL_TERMINATED_NOTES = "terminatedNotes",

  // vakuutus
  DEAL_INSURANCE = "insurance",

  // lisävarusteet
  DEAL_ACCESSORIES = "DEAL_ACCESSORIES",
  DEAL_ACCESSORIES_LEFT = "accessories",
  DEAL_ACCESSORIES_RIGHT = "accessories2",
  DEAL_INVOICE_INFO = "invoiceInfo",

  // pricing
  DEAL_PERCENTAGE = "DEAL_PERCENTAGE",
  DEAL_VAT = "DEAL_VAT",
  DEAL_VAT_24 = "DEAL_VAT_24",
  DEAL_VAT_255 = "DEAL_VAT_255",
  DEAL_VAT_HELP_TEXT = "DEAL_VAT_HELP_TEXT",

  DEAL_RESIDUAL = "DEAL_RESIDUAL",
  DEAL_RESIDUAL_VALUE = "residualValue",
  DEAL_RESIDUAL_VALUE_PERCENTAGE = "residualValuePercentage",
  DEAL_RESIDUAL_VALUE_VAT = "residualValueVAT",

  DEAL_CONTRACT_LENGTH = "contractLength",
  DEAL_CONTRACT_KILOMETERS = "contractKilometers",
  DEAL_INTEREST_RATE = "interestRate",

  DEAL_MONTHLY = "DEAL_MONTHLY",
  DEAL_MONTHLY_PAYMENT_VAT = "monthlyPaymentVAT",
  DEAL_MONTHLY_PAYMENT = "monthlyPayment",

  DEAL_FIRST_RENT = "firstLargerRent",
  DEAL_FIRST_LARGER_RENT = "DEAL_FIRST_LARGER_RENT",
  DEAL_FIRST_LARGER_RENT_VAT = "DEAL_FIRST_LARGER_RENT_VAT",
  DEAL_FUTURE_VALUE = "DEAL_FUTURE_VALUE",
  DEAL_FUTURE_VALUE_VAT = "DEAL_FUTURE_VALUE_VAT",
  DEAL_FUTURE_VALUE_LOG = "futureValue",
  DEAL_MONTHLY_INSTALLMENT_FIRST = "DEAL_MONTHLY_INSTALLMENT_FIRST",
  DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT_VAT = "monthlyInstallmentFirstInstallmentVAT",
  DEAL_MONTHLY_INSTALLMENT_FIRST_INSTALLMENT = "monthlyInstallmentFirstInstallment",

  // car pricing
  DEAL_CAR_TOTAL_PRICE = "totalPriceVAT",
  DEAL_CAR_DISCOUNT = "discount",
  DEAL_CAR_CAR_TAX = "carTax",

  DEAL_MAINTENANCE_BUDGET_TOTAL = "DEAL_MAINTENANCE_BUDGET_TOTAL",
  DEAL_CAR_MAINTENANCE_BUDGET = "maintenanceBudget",
  DEAL_CAR_MAINTENANCE_BUDGET_TOTAL = "maintenanceBudgetTotal",
  DEAL_CAR_MAINTENANCE_BUDGET_MONTHLY = "maintenanceBudgetMonthly",

  DEAL_CAR_EXTRA_TIRE_SETS = "extraTireSets",
  DEAL_CAR_EXTRA_TIRE_SET_PRICE = "extraTireSetPrice",

  DEAL_CAR_TIRE_STORAGE_PRICE = "DEAL_CAR_TIRE_STORAGE_PRICE",
  DEAL_CAR_TIRE_STORAGE_MONTHLY = "tireStorageMonthly",
  DEAL_CAR_REPLACEMENT_CAR_PRICE = "replacementCarPrice",
  DEAL_GROSS_PROFIT_VAT = "grossProfitVAT",
  DEAL_GROSS_PROFIT = "grossProfit",
  DEAL_CAR_WINTER_TIRES_PURCHASE_PRICE = "winterTiresPurchasePrice",

  DEAL_CAR_CAR_PRICE_FOR_CALCULATOR = "carPriceForCalculator",
  DEAL_CAR_TOTAL_PRICE_VAT_INCLUDING_POLLUTION = "totalPriceVATIncludingPollution",
  DEAL_EXTRA_KILOMETER_CHARGE = "extraKilometerCharge",
  DEAL_EXTRA_KILOMETER = "DEAL_EXTRA_KILOMETER",

  DEAL_KICKBACK_VAT = "kickbackVAT",
  DEAL_KICKBACK = "kickback",

  DEAL_VAT_PERCENT = "vatPercent",

  DEAL_CAR_USED_KILOMETERS = "usedKilometers",
  DEAL_MACHINE_USED_HOURS = "usedHours",

  // machine pricing
  DEAL_MACHINE_PURCHASE_PRICE = "DEAL_MACHINE_PURCHASE_PRICE",
  DEAL_MACHINE_MAINTENANCE_CONTRACT = "DEAL_MACHINE_MAINTENANCE_CONTRACT",
  DEAL_MACHINE_PURCHASE_PRICE_VAT = "DEAL_MACHINE_PURCHASE_PRICE_VAT",
  DEAL_MACHINE_MAINTENANCE_CONTRACT_VAT = "maintenanceBudgetVAT",

  DEAL_MACHINE_FUNDABLE_CAPITAL = "fundableCapital",
  DEAL_MACHINE_FUNDABLE_CAPITAL_VAT = "DEAL__MACHINE_FUNDABLE_CAPITAL_VAT",

  DEAL_BASIC_SERVICES = "DEAL_BASIC_SERVICES",
  DEAL_MAINTENANCE_SERVICES = "DEAL_MAINTENANCE_SERVICES",
  DEAL_ADDITIONAL_INFORMATION = "DEAL_ADDITIONAL_INFORMATION",
  DEAL_ADDITIONAL_EXPENSES = "DEAL_ADDITIONAL_EXPENSES",
  DEAL_ADDITIONAL_EXPENSE = "DEAL_ADDITIONAL_EXPENSE",

  DEAL_BASIC_SERVICE_ACQUISITION = "DEAL_BASIC_SERVICE_ACQUISITION",
  DEAL_BASIC_SERVICE_TIRES = "DEAL_BASIC_SERVICE_TIRES",
  DEAL_BASIC_SERVICE_RELOCATION = "DEAL_BASIC_SERVICE_RELOCATION",
  DEAL_BASIC_SERVICE_EXTRA_TIRES_TIRE_SETS = "DEAL_BASIC_SERVICE_EXTRA_TIRES_TIRE_SETS",
  DEAL_BASIC_SERVICE_REDEMPTION = "DEAL_BASIC_SERVICE_REDEMPTION",
  DEAL_BASIC_SERVICE_REDEMPTION_HUOLTO = "DEAL_BASIC_SERVICE_REDEMPTION_HUOLTO",
  DEAL_BASIC_SERVICE_REALIZATION_FEE = "DEAL_BASIC_SERVICE_REALIZATION_FEE",
  DEAL_BASIC_SERVICE_INSURANCE = "DEAL_BASIC_SERVICE_INSURANCE",

  DEAL_MAINTENANCE_SERVICE_MAINTENANCE = "DEAL_MAINTENANCE_SERVICE_MAINTENANCE",
  DEAL_MAINTENANCE_SERVICE_WEAR_AND_TEAR = "DEAL_MAINTENANCE_SERVICE_WEAR_AND_TEAR",
  DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES = "DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES",
  DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_EUROMASTER = "DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_EUROMASTER",
  DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_RENGAS_CENTER = "DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_RENGAS_CENTER",
  DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR = "DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR",
  DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR_SERVICE = "DEAL_MAINTENANCE_SERVICE_REPLACEMENT_CAR_SERVICE",
  DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE = "DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE",
  DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_EUROMASTER = "DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_EUROMASTER",
  DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_RENGAS_CENTER = "DEAL_MAINTENANCE_SERVICE_TIRE_STORAGE_RENGAS_CENTER",
  DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT = "DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT",
  DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT_OLF = "DEAL_MAINTENANCE_SERVICE_MAINTENANCE_CONTRACT_OLF",
  DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE = "DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE",
  DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE_ONLY = "DEAL_MAINTENANCE_SERVICE_SCHEDULED_MAINTENANCE_ONLY",

  DEAL_ADDITIONAL_INFORMATION_LATEST_FINANCIAL_STATEMENT = "DEAL_ADDITIONAL_INFORMATION_LATEST_FINANCIAL_STATEMENT",
  DEAL_ADDITIONAL_INFORMATION_FINANCIAL_STATEMENT_INTERVALS = "DEAL_ADDITIONAL_INFORMATION_FINANCIAL_STATEMENT_INTERVALS",
  DEAL_ADDITIONAL_INFORMATION_IS_REPLACEMENT = "DEAL_ADDITIONAL_INFORMATION_IS_REPLACEMENT",
  DEAL_ADDITIONAL_INFORMATION_THIRD_PARTY_GUARANTEED = "DEAL_ADDITIONAL_INFORMATION_THIRD_PARTY_GUARANTEED",
  DEAL_ADDITIONAL_INFORMATION_COLLATERAL_FREE = "DEAL_ADDITIONAL_INFORMATION_COLLATERAL_FREE",
  DEAL_ADDITIONAL_INFORMATION_CREDIT_ONGOING = "DEAL_ADDITIONAL_INFORMATION_CREDIT_ONGOING",
  DEAL_ADDITIONAL_INFORMATION_CREDIT_ACCEPTED = "DEAL_ADDITIONAL_INFORMATION_CREDIT_ACCEPTED",
  DEAL_ADDITIONAL_INFORMATION_CREDIT_DECLINED = "DEAL_ADDITIONAL_INFORMATION_CREDIT_DECLINED",
  DEAL_ADDITIONAL_INFORMATION_CREDIT_CONDITIONAL = "DEAL_ADDITIONAL_INFORMATION_CREDIT_CONDITIONAL",

  DEAL_CUSTOMER_NAME = "customerName",
  DEAL_CUSTOMER_BUSINESS_ID = "businessId",
  DEAL_CUSTOMER_STREET_ADDRESS = "streetAddress",
  DEAL_CUSTOMER_ZIP = "zip",
  DEAL_CUSTOMER_CITY = "city",

  DEAL_FINANCE_COMPANY = "financeCompanyName",
  DEAL_LEASING_MODEL = "leasingModel",

  DEAL_VARIATION = "DealPricingVariation",
  DEAL_BASIC_INFORMATION = "DEAL_BASIC_INFORMATION",
  DEAL_CUSTOMER_INFORMATION = "DEAL_CUSTOMER_INFORMATION",
  DEAL_NEW_CONDITION = "DEAL_NEW_CONDITION",

  DEAL_ATTACHMENTS = "DEAL_ATTACHMENTS",
  DEAL_END_DATE = "DEAL_END_DATE",
  DEAL_DOWN_PAYMENT = "DEAL_DOWN_PAYMENT",
  DEAL_DOWN_PAYMENT_VAT = "DEAL_DOWN_PAYMENT_VAT",
  DEAL_DOWN = "downPayment",

  // Payment types
  DEAL_PAYMENT_TYPE = "paymentType",
  DEAL_PAYMENT_TYPE_LEASING = "DEAL_PAYMENT_TYPE_LEASING",
  DEAL_PAYMENT_TYPE_INSTALLMENT = "DEAL_PAYMENT_TYPE_INSTALLMENT",
  DEAL_PAYMENT_TYPE_CASH_OLF = "DEAL_PAYMENT_TYPE_CASH_OLF",
  DEAL_PAYMENT_TYPE_CASH_SELLER = "DEAL_PAYMENT_TYPE_CASH_SELLER",

  // Extra fees
  DEAL_EXTRA_FEES = "DEAL_EXTRA_FEES",
  DEAL_EXTRA_FEE = "DEAL_EXTRA_FEE",
  DEAL_EXTRA_FEE_NAME = "DEAL_EXTRA_FEE_NAME",
  DEAL_EXTRA_FEE_VALUE = "DEAL_EXTRA_FEE_VALUE",
  DEAL_EXTRA_FEE_ATTACHMENTS = "DEAL_EXTRA_FEE_ATTACHMENTS",

  DEAL_SERIAL_NUMBER = "serialNumber",

  DEAL_USER_INFORMATION = "DEAL_USER_INFORMATION",

  DEAL_USER_NAME = "DEAL_USER_NAME",
  DEAL_USER_EMAIL = "DEAL_USER_EMAIL",
  DEAL_USER_PHONE_NUMBER = "DEAL_USER_PHONE_NUMBER",
  DEAL_USER_SOCIAL_SECURITY_NUMBER = "DEAL_USER_SOCIAL_SECURITY_NUMBER",
  DEAL_USER_STREET_ADDRESS = "DEAL_USER_STREET_ADDRESS",
  DEAL_USER_CITY = "DEAL_USER_CITY",
  DEAL_USER_ZIP = "DEAL_USER_ZIP",

  // Customers
  TITLE_CUSTOMERS = "TITLE_CUSTOMERS",
  CUSTOMERS_ADD_NEW = "CUSTOMERS_ADD_NEW",
  NO_CUSTOMERS = "NO_CUSTOMERS",

  // Customer
  TITLE_CUSTOMER = "TITLE_CUSTOMER",
  TITLE_CUSTOMER_NEW = "TITLE_CUSTOMER_NEW",
  CUSTOMER_NAME = "CUSTOMER_NAME",
  CUSTOMER_LEVEL = "CUSTOMER_LEVEL",
  CUSTOMER_BUSINESS_ID = "CUSTOMER_BUSINESS_ID",
  CUSTOMER_PIPEDRIVE_ORGANIZATION_ID = "CUSTOMER_PIPEDRIVE_ORGANIZATION_ID",
  CONTACT_PERSON_NAME = "contactPersonName",
  CONTACT_PERSON_EMAIL = "contactPersonEmail",
  CONTACT_PERSON_PHONE_NUMBER = "contactPersonPhoneNumber",

  // Partners
  TITLE_PARTNERS = "TITLE_PARTNERS",
  PARTNERS_ADD_NEW = "PARTNERS_ADD_NEW",
  NO_PARTNERS = "NO_PARTNERS",

  // Partner
  TITLE_PARTNER = "TITLE_PARTNER",
  TITLE_PARTNER_NEW = "TITLE_PARTNER_NEW",
  PARTNER_NAME = "PARTNER_NAME",
  PARTNER_BUSINESS_ID = "PARTNER_BUSINESS_ID",
  PARTNER_DELETE_TITLE = "PARTNER_DELETE_TITLE",
  PARTNER_DELETE_TEXT = "PARTNER_DELETE_TEXT",

  // Settings
  TITLE_SETTINGS = "TITLE_SETTINGS",
  SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK",
  PASSWORD_RESET_TEXT = "PASSWORD_RESET_TEXT",
  TITLE_INTEGRATIONS = "TITLE_INTEGRATIONS",
  
  // Nets eIdent
  NETS_EIDENT_TITLE = "NETS_EIDENT_TITLE",
  NETS_EIDENT_SUCCESS = "NETS_EIDENT_SUCCESS",
  NETS_EIDENT_FAIL = "NETS_EIDENT_FAIL",

  // Reports
  TITLE_REPORTS = "TITLE_REPORTS",

  // Users
  TITLE_USERS = "TITLE_USERS",
  USERS_ADD_NEW = "USERS_ADD_NEW",
  NO_USERS = "NO_USERS",
  USER_NAME = "USER_NAME",

  // User
  TITLE_USER = "TITLE_USER",
  TITLE_USER_NEW = "TITLE_USER_NEW",
  USER_FIRST_NAME = "USER_FIRST_NAME",
  USER_LAST_NAME = "USER_LAST_NAME",
  USER_EMAIL = "USER_EMAIL",
  USER_PHONE_NUMBER = "USER_PHONE_NUMBER",
  USER_TITLE = "USER_TITLE",
  USER_ALLOW_LOGIN = "USER_ALLOW_LOGIN",
  USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE",
  USER_SOCIAL_SECURITY_NUMBER = "USER_SOCIAL_SECURITY_NUMBER",
  USER_PASSWORD = "USER_PASSWORD",
  USER_ADDRESS = "USER_ADDRESS",

  // Finance Companies
  TITLE_FINANCE_COMPANIES = "TITLE_FINANCE_COMPANIES",
  FINANCE_COMPANIES_ADD_NEW = "FINANCE_COMPANIES_ADD_NEW",
  NO_FINANCE_COMPANIES = "NO_FINANCE_COMPANIES",

  // Finance Company
  TITLE_FINANCE_COMPANY = "TITLE_FINANCE_COMPANY",
  TITLE_FINANCE_COMPANY_NEW = "TITLE_FINANCE_COMPANY_NEW",
  FINANCE_COMPANY_NAME = "FINANCE_COMPANY_NAME",
  FINANCE_COMPANY_INTEREST_RATE = "FINANCE_COMPANY_INTEREST_RATE",
  FINANCE_COMPANY_INVOICING_FEE = "invoicingFee",
  FINANCE_COMPANY_OPENING_FEE = "openingFee",
  FINANCE_COMPANY_REGISTRATION_INFO = "FINANCE_COMPANY_REGISTRATION_INFO",
  FINANCE_COMPANY_INVOICE_PAYER = "FINANCE_COMPANY_INVOICE_PAYER",
  FINANCE_COMPANY_BILLING_INFORMATION = "FINANCE_COMPANY_BILLING_INFORMATION",
  FINANCE_COMPANY_PAYMENT_TERM = "FINANCE_COMPANY_PAYMENT_TERM",
  FINANCE_COMPANY_REFERENCE_INFORMATION = "FINANCE_COMPANY_REFERENCE_INFORMATION",
  FINANCE_COMPANY_ACQUISITION_PERIOD = "FINANCE_COMPANY_ACQUISITION_PERIOD",

  FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_DAY = "FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_DAY",
  FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_OR_15TH_DAY = "FINANCE_COMPANY_ACQUISITION_PERIOD_FIRST_OR_15TH_DAY",

  // Validation
  VALIDATION_EMAIL = "VALIDATION_EMAIL",
  VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
  VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",
  VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
  VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",
  VALIDATION_PHONE_NUMBER = "VALIDATION_PHONE_NUMBER",

  // SUFFIX
  SUFFIX_MONTHS = "SUFFIX_MONTHS",
  SUFFIX_EUROS_PER_MONTH = "SUFFIX_EUROS_PER_MONTH",
  SUFFIX_EUROS_PER_SET = "SUFFIX_EUROS_PER_SET",
  SUFFIX_HOURS = "SUFFIX_HOURS",

  // Dealerships
  TITLE_DEALERSHIPS = "TITLE_DEALERSHIPS",
  DEALERSHIP_ADD_NEW = "DEALERSHIP_ADD_NEW",
  NO_DEALERSHIPS = "NO_DEALERSHIPS",

  // Dealership
  TITLE_DEALERSHIP = "TITLE_DEALERSHIP",
  TITLE_DEALERSHIP_NEW = "TITLE_DEALERSHIP_NEW",
  DEALERSHIP_NAME = "DEALERSHIP_NAME",

  // React Select
  REACT_SELECT_PLACEHOLDER = "REACT_SELECT_PLACEHOLDER",
  REACT_SELECT_ADD_NEW = "REACT_SELECT_ADD_NEW",

  // Sort
  TITLE_SORT = "TITLE_SORT",

  // Leasing Models
  LEASING_MODEL_REILU = "LEASING_MODEL_REILU",
  LEASING_MODEL_HUOLTO = "LEASING_MODEL_HUOLTO",
  LEASING_MODEL_RAHOITUS = "LEASING_MODEL_RAHOITUS",

  // Dropzone
  DROPZONE_TEXT_PDF = "DROPZONE_TEXT_PDF",
  DROPZONE_DRAG_TEXT_PDF = "DROPZONE_DRAG_TEXT_PDF",

  // Pipedrive success modal
  PIPEDRIVE_SUCCESS_TITLE = "PIPEDRIVE_SUCCESS_TITLE",
  PIPEDRIVE_SUCCESS_TEXT = "PIPEDRIVE_SUCCESS_TEXT",

  // Dashboard
  DASHBOARD_OFFERS = "DASHBOARD_OFFERS",
  DASHBOARD_CONTRACTS = "DASHBOARD_CONTRACTS",
  DASHBOARD_PRECONTRACTS = "DASHBOARD_PRECONTRACTS",
  DASHBOARD_NO_OFFERS = "DASHBOARD_NO_OFFERS",
  DASHBOARD_LEASING_LIABILITIES_REPORT = "DASHBOARD_LEASING_LIABILITIES_REPORT",
  DASHBOARD_REQUESTED_LIMIT = "DASHBOARD_REQUESTED_LIMIT",
  DASHBOARD_REMAINING_AMOUNT = "DASHBOARD_REMAINING_AMOUNT",
  DASHBOARD_NO_LIMITS = "DASHBOARD_NO_LIMITS",
  DASHBOARD_FEES = "DASHBOARD_FEES",
  DASHBOARD_FEES_TOTAL = "DASHBOARD_FEES_TOTAL",
  DASHBOARD_FEES_TOTAL_12 = "DASHBOARD_FEES_TOTAL_12",

  // Deal accept
  DEAL_ACCEPT_DIALOG_TITLE = "DEAL_ACCEPT_DIALOG_TITLE",
  DEAL_ACCEPT_DIALOG_CONTENT = "DEAL_ACCEPT_DIALOG_CONTENT",
  DEAL_ACCEPT_PRINT = "DEAL_ACCEPT_PRINT",

  // Deal copy
  DEAL_ACTION_COPY = "DEAL_ACTION_COPY",
  DEAL_ACTION_ARCHIVE = "DEAL_ACTION_ARCHIVE",
  DEAL_ACTION_UNARCHIVE = "DEAL_ACTION_UNARCHIVE",
  DEAL_ACTION_TERMINATE = "DEAL_ACTION_TERMINATE",
  DEAL_ACTION_EIDENT_START = "DEAL_ACTION_EIDENT_START",
  DEAL_IS_USED_CAR = "DEAL_IS_USED_CAR",

  // Deal send
  DEAL_ACTION_SEND = "DEAL_ACTION_SEND",
  DEAL_SEND_TITLE = "DEAL_SEND_TITLE",
  DEAL_SEND_CONTENT = "DEAL_SEND_CONTENT",

  DEAL_SEND_RETURN_INFO_TITLE = "DEAL_SEND_RETURN_INFO_TITLE",
  DEAL_SEND_RETURN_INFO_CONTENT = "DEAL_SEND_RETURN_INFO_CONTENT",
  DEAL_SEND_RETURN_INFO_LABEL = "DEAL_SEND_RETURN_INFO_LABEL",
  DEAL_SEND_RETURN_INFO_RECIPIENTS = "DEAL_SEND_RETURN_INFO_RECIPIENTS",
  DEAL_RETURN_INFO_BUTTON_TITLE = "DEAL_RETURN_INFO_BUTTON_TITLE",
  DEAL_RETURN_INFO_TITLE = "DEAL_RETURN_INFO_TITLE",

  // Deal order confirmation
  DEAL_ORDER_CONFIRMATION = "DEAL_ORDER_CONFIRMATION",
  DEAL_ORDER_CONFIRMATION_PRINTED = "DEAL_ORDER_CONFIRMATION_PRINTED",

  DEAL_ORDER_CONFIRMATION_CAR_INFORMATION = "DEAL_ORDER_CONFIRMATION_CAR_INFORMATION",
  DEAL_ORDER_CONFIRMATION_MACHINE_INFORMATION = "DEAL_ORDER_CONFIRMATION_MACHINE_INFORMATION",
  DEAL_ORDER_CONFIRMATION_REGISTRATION_INFO = "DEAL_ORDER_CONFIRMATION_REGISTRATION_INFO",
  DEAL_ORDER_CONFIRMATION_BILLING_DETAILS = "DEAL_ORDER_CONFIRMATION_BILLING_DETAILS",

  DEAL_ORDER_CONFIRMATION_BRAND = "DEAL_ORDER_CONFIRMATION_BRAND",
  DEAL_ORDER_CONFIRMATION_MODEL = "DEAL_ORDER_CONFIRMATION_MODEL",
  DEAL_ORDER_CONFIRMATION_SALE_NUMBER = "DEAL_ORDER_CONFIRMATION_SALE_NUMBER",
  DEAL_ORDER_CONFIRMATION_CO2_EMISSIONS = "DEAL_ORDER_CONFIRMATION_CO2_EMISSIONS",
  DEAL_ORDER_CONFIRMATION_ACCESSORIES = "DEAL_ORDER_CONFIRMATION_ACCESSORIES",
  DEAL_ORDER_CONFIRMATION_TOTAL_PRICE = "DEAL_ORDER_CONFIRMATION_TOTAL_PRICE",
  DEAL_ORDER_CONFIRMATION_TOTAL_PRICE_VAT = "DEAL_ORDER_CONFIRMATION_TOTAL_PRICE_VAT",
  DEAL_ORDER_CONFIRMATION_DISCOUNT = "DEAL_ORDER_CONFIRMATION_DISCOUNT",
  DEAL_ORDER_CONFIRMATION_CAR_TAX = "DEAL_ORDER_CONFIRMATION_CAR_TAX",
  DEAL_ORDER_CONFIRMATION_REGISTRATION_NUMBER = "DEAL_ORDER_CONFIRMATION_REGISTRATION_NUMBER",
  DEAL_ORDER_CONFIRMATION_SERIAL_NUMBER = "DEAL_ORDER_CONFIRMATION_SERIAL_NUMBER",

  DEAL_ORDER_CONFIRMATION_OWNER = "DEAL_ORDER_CONFIRMATION_OWNER",
  DEAL_ORDER_CONFIRMATION_HOLDER_1 = "DEAL_ORDER_CONFIRMATION_HOLDER_1",
  DEAL_ORDER_CONFIRMATION_HOLDER_2 = "DEAL_ORDER_CONFIRMATION_HOLDER_2",
  DEAL_ORDER_CONFIRMATION_HOLDER_3 = "DEAL_ORDER_CONFIRMATION_HOLDER_3",
  DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TITLE = "DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TITLE",
  DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_CAR = "DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_CAR",
  DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_MACHINE = "DEAL_ORDER_CONFIRMATION_INSURANCE_INFORMATION_TEXT_MACHINE",
  DEAL_ORDER_CONFIRMATION_INSURANCE_COMPANY = "DEAL_ORDER_CONFIRMATION_INSURANCE_COMPANY",

  DEAL_ORDER_CONFIRMATION_INVOICE_PAYER = "DEAL_ORDER_CONFIRMATION_INVOICE_PAYER",
  DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY = "DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY",
  DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY_TEXT = "DEAL_ORDER_CONFIRMATION_INVOICE_DELIVERY_TEXT",
  DEAL_ORDER_CONFIRMATION_PAYMENT_TERM_TITLE = "DEAL_ORDER_CONFIRMATION_PAYMENT_TERM_TITLE",
  DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TITLE = "DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TITLE",
  DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_CAR = "DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_CAR",
  DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_MACHINE = "DEAL_ORDER_CONFIRMATION_REFERENCE_INFORMATION_TEXT_MACHINE",

  DEAL_ORDER_CONFIRMATION_FIRST_PAYMENT_HELP_TITLE = "DEAL_ORDER_CONFIRMATION_FIRST_PAYMENT_HELP_TITLE",

  DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE = "DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TITLE",
  DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TEXT = "DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_HELP_TEXT",
  DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_OLF = "DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_OLF",
  DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_SELLER = "DEAL_ORDER_CONFIRMATION_DOWN_PAYMENT_CASH_SELLER",

  DEAL_ORDER_CONFIRMATION_HANDOVER = "DEAL_ORDER_CONFIRMATION_HANDOVER",
  DEAL_ORDER_CONFIRMATION_HANDOVER_INFO = "DEAL_ORDER_CONFIRMATION_HANDOVER_INFO",
  DEAL_ORDER_CONFIRMATION_HANDOVER_INFO_TEXT = "DEAL_ORDER_CONFIRMATION_HANDOVER_INFO_TEXT",

  DEAL_ORDER_CONFIRMATION_VALIDITY = "DEAL_ORDER_CONFIRMATION_VALIDITY",
  DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY = "DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY",
  DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY_TEXT = "DEAL_ORDER_CONFIRMATION_FINANCIAL_ELIGIBILITY_TEXT",
  DEAL_ORDER_CONFIRMATION_CREDIT_DECISION = "DEAL_ORDER_CONFIRMATION_CREDIT_DECISION",
  DEAL_ORDER_CONFIRMATION_CREDIT_DECISION_TEXT = "DEAL_ORDER_CONFIRMATION_CREDIT_DECISION_TEXT",

  // Residual value liability / jäännösarvovastuu
  DEAL_RESIDUAL_VALUE_LIABILITY = "residualValueLiability",
  DEAL_RESIDUAL_VALUE_LIABILITY_CUSTOMER = "DEAL_RESIDUAL_VALUE_LIABILITY_CUSTOMER",
  DEAL_RESIDUAL_VALUE_LIABILITY_OLF = "DEAL_RESIDUAL_VALUE_LIABILITY_OLF",
  DEAL_RESIDUAL_VALUE_LIABILITY_SELLER = "DEAL_RESIDUAL_VALUE_LIABILITY_SELLER",

  // Limit
  LIMITS = "LIMITS",
  LIMIT = "LIMIT",
  LIMIT_FINANCE_COMPANY = "LIMIT_FINANCE_COMPANY",
  LIMIT_AMOUNT = "LIMIT_AMOUNT",

  // Reports
  REPORTS_HANDOVER_DATE = "REPORTS_HANDOVER_DATE",
  REPORTS_HANDOVER_TOTAL = "REPORTS_HANDOVER_TOTAL",

  // Invoices
  NO_INVOICES = "NO_INVOICES",
  INVOICES_ATTACH_INVOICE_TITLE = "INVOICES_ATTACH_INVOICE_TITLE",
  INVOICES_HIDE_INVOICE_TITLE = "INVOICES_HIDE_INVOICE_TITLE",
  INVOICES_HIDE_INVOICE_TEXT = "INVOICES_HIDE_INVOICE_TEXT",

  // Invoice
  INVOICE_TOTAL_VAT_0 = "INVOICE_TOTAL_VAT_0",
  INVOICE_TOTAL_VAT_10 = "INVOICE_TOTAL_VAT_10",
  INVOICE_TOTAL_VAT_24 = "INVOICE_TOTAL_VAT_24",
  INVOICE_TOTAL = "INVOICE_TOTAL",

  INVOICE_ROW_PRODUCT = "INVOICE_ROW_PRODUCT",
  INVOICE_ROW_QUANTITY = "INVOICE_ROW_QUANTITY",
  INVOICE_ROW_UNIT = "INVOICE_ROW_UNIT",
  INVOICE_ROW_UNIT_PRICE = "INVOICE_ROW_UNIT_PRICE",
  INVOICE_ROW_TOTAL = "INVOICE_ROW_TOTAL",
  INVOICE_ROW_VAT_PERCENT = "INVOICE_ROW_VAT_PERCENT",

  // Attach invoice dialog
  ATTACH_INVOICE_DEAL = "ATTACH_INVOICE_DEAL",
  ATTACH_INVOICE_DEAL_SEARCH = "ATTACH_INVOICE_DEAL_SEARCH",

  // Leasing Liabilities
  LEASING_LIABILITIES_SHORT = "LEASING_LIABILITIES_SHORT",
  LEASING_LIABILITIES_LONG = "LEASING_LIABILITIES_LONG",

  // Maintenance Budget Status
  MAINTENANCE_BUDGET_STATUS_TITLE = "MAINTENANCE_BUDGET_STATUS_TITLE",
  MAINTENANCE_BUDGET_STATUS_TOTAL = "MAINTENANCE_BUDGET_STATUS_TOTAL",
  MAINTENANCE_BUDGET_STATUS_USED = "MAINTENANCE_BUDGET_STATUS_USED",
  MAINTENANCE_BUDGET_STATUS_REMAINING = "MAINTENANCE_BUDGET_STATUS_REMAINING",

  // Edit invoice
  EDIT_INVOICE_TITLE = "EDIT_INVOICE_TITLE",
  EDIT_INVOICE_TEXT = "EDIT_INVOICE_TEXT",

  // Detach invoice
  DETACH_INVOICE_TITLE = "DETACH_INVOICE_TITLE",
  DETACH_INVOICE_TEXT = "DETACH_INVOICE_TEXT",

  // Errors
  TOKEN_ERROR = "TOKEN_ERROR",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",
  FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR",
  IDENTIFIER_ERROR = "IDENTIFIER_ERROR",
  DATABASE_ERROR = "DATABASE_ERROR",
  DATA_EMPTY_ERROR = "DATA_EMPTY_ERROR",
  ORGANIZATION_ERROR = "ORGANIZATION_ERROR",
  NO_ACCESS_ERROR = "NO_ACCESS_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  PIPEDRIVE_NOT_CONNECTED_ERROR = "PIPEDRIVE_NOT_CONNECTED_ERROR",
  NO_CONTRACT_LENGTH_ERROR = "NO_CONTRACT_LENGTH_ERROR",
  SEARCH_QUERY_STRING_ERROR = "SEARCH_QUERY_STRING_ERROR",
  API_ERROR_TITLE = "API_ERROR_TITLE",
  API_ERROR_TEXT = "API_ERROR_TEXT",
  API_ERROR_REQUIRED_FIELDS_MISSING_BILTEMA = "API_ERROR_REQUIRED_FIELDS_MISSING_BILTEMA",
  API_ERROR_REQUIRED_FIELDS_MISSING_NETWHEELS = "API_ERROR_REQUIRED_FIELDS_MISSING_NETWHEELS",
  ARCHIVED_ERROR = "ARCHIVED_ERROR",

  // Maintenance Budget Report
  MAINTENANCE_BUDGET_REPORT_CHOOSE_DATE = "MAINTENANCE_BUDGET_REPORT_CHOOSE_DATE",
  MAINTENANCE_BUDGET_REPORT_USE_END_DATE = "MAINTENANCE_BUDGET_REPORT_USE_END_DATE",
  MAINTENANCE_BUDGET_REPORT_CHOOSE_END_DATE = "MAINTENANCE_BUDGET_REPORT_CHOOSE_END_DATE",

  // Selects
  SELECT_FINANCE_COMPANY_ID = "DEALS_FINANCE_COMPANY_ID",
  SELECT_STATUS = "DEALS_STATUS",
  SELECT_SELLER_ID = "DEALS_SELLER_ID",
  SELECT_CUSTOMER_ID = "DEALS_CUSTOMER_ID",
  SELECT_TYPE = "DEALS_TYPE",

  // Deal notes
  DEAL_NOTES = "DEAL_NOTES",
  DEAL_NOTE_TEXT = "DEAL_NOTE_TEXT",
  DEAL_NOTES_NEW = "DEAL_NOTES_NEW",
  DEAL_NOTES_NEW_ERROR = "DEAL_NOTES_NEW_ERROR",
  DEAL_NOTES_DELETE_TITLE = "DEAL_NOTES_DELETE_TITLE,",
  DEAL_NOTES_DELETE_TEXT = "DEAL_NOTES_DELETE_TEXT",

  // Deal terminate
  DEAL_TERMINATE_FINAL_DEBT = "DEAL_TERMINATE_FINAL_DEBT",
  DEAL_TERMINATE_FINAL_DEBT_DATE = "DEAL_TERMINATE_FINAL_DEBT_DATE",
  DEAL_TERMINATE_PURCHASE_PRICE = "DEAL_TERMINATE_PURCHASE_PRICE",
  DEAL_TERMINATE_MAINTENANCE_BUDGET = "DEAL_TERMINATE_MAINTENANCE_BUDGET",
  DEAL_TERMINATE_FUNDABLE_CAPITAL = "DEAL_TERMINATE_FUNDABLE_CAPITAL",
  DEAL_TERMINATE_HANDLING_FEE = "DEAL_TERMINATE_HANDLING_FEE",
  DEAL_TERMINATE_TERMINATION_FEE = "DEAL_TERMINATE_TERMINATION_FEE",
  DEAL_TERMINATE_TERMINATION_FEE_VAT = "DEAL_TERMINATE_TERMINATION_FEE_VAT",
  DEAL_TERMINATE_TERMINATION_FEE_VAT_AMOUNT = "DEAL_TERMINATE_TERMINATION_FEE_VAT_AMOUNT",
  DEAL_TERMINATE_UNDER_KILOMETERS = "DEAL_TERMINATE_UNDER_KILOMETERS",
  DEAL_TERMINATE_UNDER_KILOMETER_CREDIT = "DEAL_TERMINATE_UNDER_KILOMETER_CREDIT",
  DEAL_TERMINATE_USED_KILOMETERS = "DEAL_TERMINATE_USED_KILOMETERS",
  DEAL_TERMINATE_EXTRA_KILOMETERS = "DEAL_TERMINATE_EXTRA_KILOMETERS",
  DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE = "DEAL_TERMINATE_EXTRA_KILOMETER_CHARGE",
  DEAL_TERMINATE_MACHINE_ID = "DEAL_TERMINATE_MACHINE_ID",

  // Deal termination calculation
  DEAL_TERMINATION_CALCULATION_TITLE = "DEAL_TERMINATION_CALCULATION_TITLE",
  DEAL_TERMINATION_CALCULATION_SELLER_TITLE = "DEAL_TERMINATION_CALCULATION_SELLER_TITLE",
  DEAL_TERMINATION_CALCULATION_CUSTOMER_TITLE = "DEAL_TERMINATION_CALCULATION_CUSTOMER_TITLE",
  DEAL_TERMINATION_CALCULATION_TARGET_TITLE = "DEAL_TERMINATION_CALCULATION_TARGET_TITLE",

  DEAL_TERMINATION_CALCULATION_ORGANIZATION = "DEAL_TERMINATION_CALCULATION_ORGANIZATION",
  DEAL_TERMINATION_CALCULATION_ADDRESS = "DEAL_TERMINATION_CALCULATION_ADDRESS",
  DEAL_TERMINATION_CALCULATION_PHONE = "DEAL_TERMINATION_CALCULATION_PHONE",
  DEAL_TERMINATION_CALCULATION_CITY = "DEAL_TERMINATION_CALCULATION_CITY",
  DEAL_TERMINATION_CALCULATION_START_DATE = "DEAL_TERMINATION_CALCULATION_START_DATE",
  DEAL_TERMINATION_CALCULATION_END_DATE = "DEAL_TERMINATION_CALCULATION_END_DATE",
  DEAL_TERMINATION_CALCULATION_CONTRACT_KILOMETERS = "DEAL_TERMINATION_CALCULATION_CONTRACT_KILOMETERS",
  DEAL_TERMINATION_CALCULATION_USED_KILOMETERS = "DEAL_TERMINATION_CALCULATION_USED_KILOMETERS",
  DEAL_TERMINATION_CALCULATION_RESIDUAL_VALUE = "DEAL_TERMINATION_CALCULATION_RESIDUAL_VALUE",

  DEAL_TERMINATION_CALCULATION_FINAL_DEBT = "DEAL_TERMINATION_CALCULATION_FINAL_DEBT",
  DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET = "DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET",
  DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_USED = "DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_USED",
  DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_UNUSED = "DEAL_TERMINATION_CALCULATION_MAINTENANCE_BUDGET_UNUSED",
  DEAL_TERMINATION_CALCULATION_HANDLING_FEE = "DEAL_TERMINATION_CALCULATION_HANDLING_FEE",
  DEAL_TERMINATION_CALCULATION_TOTAL = "DEAL_TERMINATION_CALCULATION_TOTAL",

  DEAL_TERMINATION_CALCULATION_PURCHASE_PRICE = "DEAL_TERMINATION_CALCULATION_PURCHASE_PRICE",
  DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT = "DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT",
  DEAL_TERMINATION_CALCULATION_TERMINATION_FEE = "DEAL_TERMINATION_CALCULATION_TERMINATION_FEE",
  DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT_AMOUNT = "DEAL_TERMINATION_CALCULATION_TERMINATION_FEE_VAT_AMOUNT",

  DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_1 = "DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_1",
  DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_2 = "DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_2",
  DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_3 = "DEAL_TERMINATION_CALCULATION_FOOTER_TEXT_3",
  DEAL_TERMINATION_CALCULATION_FOOTER_DATE = "DEAL_TERMINATION_CALCULATION_FOOTER_DATE",
  DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE = "DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE",
  DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE_CLARIFICATION = "DEAL_TERMINATION_CALCULATION_FOOTER_CUSTOMER_SIGNATURE_CLARIFICATION",
  DEAL_TERMINATION_CALCULATION_FOOTER_SELLER_SIGNATURE = "DEAL_TERMINATION_CALCULATION_FOOTER_SELLER_SIGNATURE",

  // Deal Maintenance Info
  DEAL_PRINT_MAINTENANCE_INFO_TITLE = "DEAL_PRINT_MAINTENANCE_INFO_TITLE",
  DEAL_MAINTENANCE_DIALOG_TEXT = "DEAL_MAINTENANCE_DIALOG_TEXT",
  DEAL_MAINTENANCE_INFO_TITLE = "DEAL_MAINTENANCE_INFO_TITLE",
  
  DEAL_MAINTENANCE_INFO_CAR_OWNER = "DEAL_MAINTENANCE_INFO_CAR_OWNER",
  DEAL_MAINTENANCE_INFO_SERVICES = "DEAL_MAINTENANCE_INFO_SERVICES",
  DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER = "DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER",
  DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_OLF = "DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_OLF",
  DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_IMPORTER = "DEAL_MAINTENANCE_INFO_SERVICE_PROVIDER_IMPORTER",
  DEAL_MAINTENANCE_INFO_CONFIRM = "DEAL_MAINTENANCE_INFO_CONFIRM",
  DEAL_MAINTENANCE_INFO_EINVOICE_INSTRUCTIONS = "DEAL_MAINTENANCE_INFO_EINVOICE_INSTRUCTIONS",
  DEAL_MAINTENANCE_INFO_EINVOICE = "DEAL_MAINTENANCE_INFO_EINVOICE",
  DEAL_MAINTENANCE_INFO_BROKER = "DEAL_MAINTENANCE_INFO_BROKER",
  DEAL_MAINTENANCE_INFO_BROKER_ID = "DEAL_MAINTENANCE_INFO_BROKER_ID",
  DEAL_MAINTENANCE_INFO_BROKER_ID_BANK = "DEAL_MAINTENANCE_INFO_BROKER_ID_BANK",
  DEAL_MAINTENANCE_INFO_EXTRA_INFO = "DEAL_MAINTENANCE_INFO_EXTRA_INFO",

  // Deal Signature dialog
  DEAL_SIGNATURE_DIALOG_TEXT_1 = "DEAL_SIGNATURE_DIALOG_TEXT_1",
  DEAL_SIGNATURE_DIALOG_TEXT_2 = "DEAL_SIGNATURE_DIALOG_TEXT_2",
  DEAL_SIGNATURE_IS_AUTHORIZED = "DEAL_SIGNATURE_IS_AUTHORIZED",
  // Deal confirm signature dialog
  DEAL_CONFIRM_SIGNATURE_DIALOG_TEXT = "DEAL_CONFIRM_SIGNATURE_DIALOG_TEXT",

  // Deal Identification Info
  DEAL_IDENTIFICATION_TITLE = "DEAL_IDENTIFICATION_TITLE",
  DEAL_IDENTIFICATION_IDENTIFIED = "DEAL_IDENTIFICATION_IDENTIFIED",
  DEAL_IDENTIFICATION_BANK_NAME = "DEAL_IDENTIFICATION_BANK_NAME",

  // MultiFactorAuthDialog
  MULTI_FACTOR_AUTH_DIALOG_TITLE = "MULTI_FACTOR_AUTH_DIALOG_TITLE",
  MULTI_FACTOR_AUTH_DIALOG_CODE = "MULTI_FACTOR_AUTH_DIALOG_CODE",
  MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER = "MULTI_FACTOR_AUTH_DIALOG_CODE_PLACEHOLDER",

  // DRAFT
  DRAFT_ACCESSORIES = "DRAFT_ACCESSORIES",
  DRAFT_TOTAL_PRICE_VAT = "DRAFT_TOTAL_PRICE_VAT",
  DRAFT_EXCHANGE_DESCRIPTION = "partnerExchangeDescription",
  DRAFT_EXCHANGE_VALUE = "partnerExchangeValue",
  DRAFT_GROSS_PROFIT = "partnerGrossProfit",
  DRAFT_ARCHIVE_CONFIRM = "DRAFT_ARCHIVE_CONFIRM",
  DRAFT_UNARCHIVE_CONFIRM = "DRAFT_UNARCHIVE_CONFIRM",
  DRAFT_ACQUISITION = "DRAFT_ACQUISITION",
  DRAFT_VARIATION = "DRAFT_VARIATION",
  DRAFT_PARTNER = "DRAFT_PARTNER",
  DRAFT_ATTACHMENTS = "DRAFT_ATTACHMENTS",
  DRAFT_COPY = "DRAFT_COPY",
  DRAFT_NOT_SAVED = "DRAFT_NOT_SAVED",
  DRAFT_SAVED = "DRAFT_SAVED",
  DRAFT_RESIDUAL = "partnerResidual",

  // DRAFTS
  DRAFT_NEW = "DRAFT_NEW",
  DRAFTS_SENT = "DRAFTS_SENT",
  DRAFTS_START = "DRAFTS_START",
  DRAFTS_ARCHIVED_SEARCH = "DRAFTS_ARCHIVED_SEARCH",
  PARTNER_DEALS = "PARTNER_DEALS",
  DRAFTS_PARTNER_ORGANIZATION = "DRAFTS_PARTNER_ORGANIZATION",
  DRAFTS_DEAL_NAME = "DRAFTS_DEAL_NAME",
  DRAFTS_PRICE = "DRAFTS_PRICE",
  DRAFTS_CONTRACT = "DRAFTS_CONTRACT",
  DRAFTS_NO_DEALS = "DRAFTS_NO_DEALS",

  // LOG ITEMS
  LOGS = "LOGS",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  ACTION = "ACTION",
  LOG_FIELD_NAME = "LOG_FIELD_NAME",
  LOG_OLD_VALUE = "LOG_OLD_VALUE",
  LOG_NEW_VALUE = "LOG_NEW_VALUE",

  // log items changes table
  LOG_TEXT = "text",
  LOG_ACCEPTED = "accepted",
  LOG_TRUE = "true",
  LOG_FALSE = "false",
  LOG_NAME = "name",
  LOG_CONDITION = "Condition",
  LOG_ORGANIZATION = "Organization",
  LOG_EXTRA_FEE = "ExtraFee",
  LOG_VALUE = "value",
  
}
