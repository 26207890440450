import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  EBasicService,
  EDealEdit,
  EMaintenanceService,
} from "../../../../containers/Deals/Deal/Deal";
import DealContext from "../../../../context/DealContext";
import DealVariationConditionContext from "../../../../context/DealVariationConditionContext";
import { EInputUpdateAction } from "../../../../context/InputContext";
import { EDealStatus, EDealType, ELeasingModel } from "../../../../enums";
import { useAuthUser } from "../../../../hooks/useAuthUser";
import { IAppState, ICondition, IDealership, IFinanceCompany } from "../../../../interfaces";
import { defaultPricingVariation } from "../../../../shared/deal-utils";
import {
  addCondition,
  generateUUID,
  removeCondition,
  toTwoDecimals,
  updateConditionHandler,
  updateInputHandler,
  validateInput,
} from "../../../../shared/utility";
import { ETranslation } from "../../../../translations/translation-keys";
import Button from "../../../UI/Button/Button";
import Fieldset from "../../../UI/Fieldset/Fieldset";
import Input, { IInputOptions } from "../../../UI/Input/Input";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import DealVariationEdit from "../DealVariation/DealVariationEdit/DealVariationEdit";
import ExtraFee from "../DealVariation/ExtraFee/ExtraFee";

const DealEdit: React.FC = () => {
  const {
    id,
    saveOrUpdateDeal,
    inputs,
    setInputs,
    goBack,
    variations,
    setVariations,
    isValid,
    setValid,
    deal,
    isDisabled,
    archiveDeal,
    customerOptions,
    financeCompanyOptions,
    dealershipOptions,
    setDealTerminateDialog,
    updateExtraFee,
    addExtraFee,
    removeExtraFee,
    copyExtraFee,
    additionalExpenses,
    setAdditionalExpenses,
    biltemaHandler,
  } = useContext(DealContext);

  const { loading: biltemaLoading } = useSelector((state: IAppState) => state.biltema);

  const [variationContractLength, setVariationContractLength] = useState<
    string | number
  >("");
  const [variationResidualValue, setVariationResidualValue] = useState<
    string | number
  >("");
  const [variationContractKilometers, setVariationContractKilometers] =
    useState<string | number>("");
  const [variationExtraTireSets, setVariationExtraTireSets] = useState<
    string | number
  >("");
  const authUser = useAuthUser();
  // const [redemption, setRedemption] = useState<ICondition | null>(null);
  // const [extraTires, setExtraTires] = useState<ICondition | null>(null);

  const { t } = useTranslation();

  const isPreoffer = id && id.startsWith("preoffer");
  const isNew = id === "new" || isPreoffer;

  const dealType = inputs[EDealEdit.TYPE].value as EDealType;
  const isCar = dealType === EDealType.CAR;
  const isMachine = dealType === EDealType.MACHINE;

  const [editableFields, setEditableFields] = useState<EDealEdit[]>([]);
  const editableFieldsDisabled = authUser?.isAdmin
    ? false
    : deal?.archived
    ? true
    : deal?.status === EDealStatus.CONTRACT
    ? false
    : isDisabled;
  useEffect(() => {
    if (!editableFieldsDisabled) {
      setEditableFields([
        EDealEdit.INVOICE_REFERENCE,
        EDealEdit.COST_CENTER,
        EDealEdit.BRAND,
        EDealEdit.MODEL,
        EDealEdit.REGISTRATION_NUMBER,
        EDealEdit.SERIAL_NUMBER,
        EDealEdit.modelYear,
        EDealEdit.usedKilometers,
        EDealEdit.usedHours
      ]);
    }
    if (authUser?.isAdmin) {
      setEditableFields([
        EDealEdit.INVOICE_REFERENCE,
        EDealEdit.COST_CENTER,
        EDealEdit.BRAND,
        EDealEdit.MODEL,
        EDealEdit.REGISTRATION_NUMBER,
        EDealEdit.SERIAL_NUMBER,
        EDealEdit.NAME,
        EDealEdit.CUSTOMER,
        EDealEdit.CUSTOMER_NAME,
        EDealEdit.CUSTOMER_BUSINESS_ID,
        EDealEdit.CUSTOMER_STREET_ADDRESS,
        EDealEdit.CUSTOMER_ZIP,
        EDealEdit.CUSTOMER_CITY,
        EDealEdit.contactPersonName,
        EDealEdit.contactPersonEmail,
        EDealEdit.contactPersonPhoneNumber,
        EDealEdit.DEALERSHIP,
        EDealEdit.DEALERSHIP_SELLER_NAME,
        EDealEdit.DEALERSHIP_SALE_NUMBER,
        EDealEdit.REGISTRATION_DATE,
        EDealEdit.SIGNING_DATE,
        EDealEdit.SURETY,
        EDealEdit.HANDOVER_DATE,
        EDealEdit.INSURANCE,
        EDealEdit.ACCESSORIES,
        EDealEdit.ACCESSORIES2,
        EDealEdit.USER_NAME,
        EDealEdit.USER_EMAIL,
        EDealEdit.USER_PHONE_NUMBER,
        EDealEdit.USER_SOCIAL_SECURITY_NUMBER,
        EDealEdit.USER_STREET_ADDRESS,
        EDealEdit.USER_CITY,
        EDealEdit.USER_ZIP,
        EDealEdit.INVOICE_REFERENCE,
        EDealEdit.COST_CENTER,
        EDealEdit.INVOICE_PAYER,
        EDealEdit.FINANCE_COMPANY,
        EDealEdit.FINANCE_COMPANY_CONTRACT_NUMBER,
        EDealEdit.hideVatPrices,
        EDealEdit.insuranceCertificateReceived,
        EDealEdit.invoiceInfo,
        EDealEdit.BASIC_SERVICES,
        EDealEdit.ADDITIONAL_INFORMATION,
        EDealEdit.TOTAL_PRICE,
        EDealEdit.TOTAL_PRICE_VAT,
        EDealEdit.DISCOUNT,
        EDealEdit.CAR_TAX,
        EDealEdit.INVOICING_FEE,
        EDealEdit.OPENING_FEE,
        EDealEdit.CO2_EMISSIONS,
        EDealEdit.TAKE_HOME_VEHICLE,
        EDealEdit.CAR_BENEFIT,
        EDealEdit.LEASING_MODEL,
        EDealEdit.modelYear,
        EDealEdit.maintenanceServices,
        EDealEdit.usedKilometers,
        EDealEdit.usedHours
      ]);
    }
  }, [authUser, editableFieldsDisabled]);

  const leasingModel = inputs[EDealEdit.LEASING_MODEL].value as ELeasingModel;
  const basicServices = inputs[EDealEdit.BASIC_SERVICES].value as ICondition[];
  const totalPrice = inputs[EDealEdit.TOTAL_PRICE].value as number;
  
  const carTax = inputs[EDealEdit.CAR_TAX].value as number;
  const insurance = inputs[EDealEdit.INSURANCE].value as string;
  const contactName = inputs[EDealEdit.contactPersonName].value as string;
  const contactEmail = inputs[EDealEdit.contactPersonEmail].value as string;
  const contactPhone = inputs[EDealEdit.contactPersonPhoneNumber].value as string;
  const dealership = inputs[EDealEdit.DEALERSHIP].value as IDealership;
  const financeCompany = inputs[EDealEdit.FINANCE_COMPANY].value as IFinanceCompany;

  const registrationNumber = inputs[EDealEdit.REGISTRATION_NUMBER]
    .value as string;
  const invoiceReference = inputs[EDealEdit.INVOICE_REFERENCE].value as string;
  const maintenanceServices = inputs[EDealEdit.maintenanceServices].value as ICondition[];

  const vatPercent = inputs[EDealEdit.vatPercent].value as number;

  // useEffect(() => {
  //   const redemption = basicServices.find(s => s.id === EBasicService.REDEMPTION);
  //   setRedemption(redemption || null);
  //   const extraTires = basicServices.find(s => s.id === EBasicService.EXTRA_TIRES);
  //   setExtraTires(extraTires || null);
  // }, [basicServices]);

  // set redemption text based on leasing model
  useEffect(() => {
    // console.log("useEffect() leasingModel changed:", leasingModel)
    if (!basicServices) return;
    const redemption = basicServices.find(
      (s) => s.id === EBasicService.REDEMPTION
    );

    const updateRedemption = !redemption || (redemption && redemption.autoUpdate);

    const servicesById: Record<string, ICondition | null> = {
      [EMaintenanceService.EXTRA_TIRES]: null,
      [EMaintenanceService.EXTRA_TIRES_EUROMASTER]: null,
      [EMaintenanceService.EXTRA_TIRES_RENGAS_CENTER]: null,
    };
    for (const service of maintenanceServices) {
      if (servicesById.hasOwnProperty(service.id)) {
        servicesById[service.id] = service;
      }
    }
    const extraTires = servicesById[EMaintenanceService.EXTRA_TIRES];
    const extraTiresEuromaster = servicesById[EMaintenanceService.EXTRA_TIRES_EUROMASTER];
    const extraTiresRengasCenter = servicesById[EMaintenanceService.EXTRA_TIRES_RENGAS_CENTER];
    const updateExtraTires = isCar && [extraTires, extraTiresEuromaster, extraTiresRengasCenter].every((service) => !service || service.autoUpdate);

    // console.log("useEffect() basic services", leasingModel, variationContractLength, variationResidualValue, variationContractKilometers, variationExtraTireSets);
    // if(true)return;

    // console.log(updateRedemption, updateExtraTires);
    if (!updateRedemption && !updateExtraTires) return;

    // console.log("useEffect() update basic services");

    let redemptionText = "";

    if (updateRedemption) {
      // console.log("update redemption");

      switch (leasingModel) {
        case ELeasingModel.REILU:
        case ELeasingModel.RAHOITUS:
          redemptionText = t(ETranslation.DEAL_BASIC_SERVICE_REDEMPTION);
          break;
        case ELeasingModel.HUOLTO:
          redemptionText = t(ETranslation.DEAL_BASIC_SERVICE_REDEMPTION_HUOLTO);
          break;
        default:
          return;
      }

      let redemptionExtraPost: string[] = [];

      if (+variationContractLength > 0)
        redemptionExtraPost.push(
          `${variationContractLength}${t(ETranslation.SUFFIX_MONTHS)}`
        );
      if (isCar && +variationContractKilometers > 0)
        redemptionExtraPost.push(`${+variationContractKilometers / 1000}tkm`);
      if (+variationResidualValue > 0)
        redemptionExtraPost.push(`${variationResidualValue}€`);

      if (redemptionExtraPost.length > 0)
        redemptionText = `${redemptionText} ${redemptionExtraPost.join("/")}`;
    }

    let extraTiresText = "";
    let extraTiresTextEuromaster = "";
    let extraTiresTextRengasCenter = "";
    let extraTiresExtraPostString = "";

    if (updateExtraTires) {
      // console.log("update extra tires");

      extraTiresText = t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES);
      extraTiresTextEuromaster = t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_EUROMASTER);
      extraTiresTextRengasCenter = t(ETranslation.DEAL_MAINTENANCE_SERVICE_EXTRA_TIRES_RENGAS_CENTER);
      let extraTiresExtraPost: string[] = [];
      if (variationExtraTireSets && +variationExtraTireSets > 0)
        extraTiresExtraPost.push(
          `(${variationExtraTireSets} ${t(
            ETranslation.DEAL_BASIC_SERVICE_EXTRA_TIRES_TIRE_SETS
          )})`
        );
      if (extraTiresExtraPost.length > 0) {
        extraTiresExtraPostString = extraTiresExtraPost.join("/");
        extraTiresText = `${extraTiresText} ${extraTiresExtraPostString}`;
        extraTiresTextEuromaster = `${extraTiresTextEuromaster} ${extraTiresExtraPostString}`;
        extraTiresTextRengasCenter = `${extraTiresTextRengasCenter} ${extraTiresExtraPostString}`;
      }
    }

    setInputs((inputs) => {
      const newInputs = { ...inputs };

      const basicServices = newInputs[EDealEdit.BASIC_SERVICES]
        .value as ICondition[];

      // console.log("setInputs", updateRedemption, updateExtraTires);

      if (updateRedemption) {
        // console.log("set redemption");
        if (redemption) {
          redemption.text = redemptionText;
        } else {
          basicServices.push({
            id: EBasicService.REDEMPTION,
            accepted: false,
            text: redemptionText,
            autoUpdate: true,
          });
        }
      }

      const maintenanceServices = newInputs[EDealEdit.maintenanceServices]
        .value as ICondition[];

      if (updateExtraTires) {
        // console.log("set extra tires");
        if (extraTires || extraTiresEuromaster || extraTiresRengasCenter) {
          if (extraTires) extraTires.text = extraTiresText;
          if (extraTiresEuromaster) extraTiresEuromaster.text = extraTiresTextEuromaster;
          if (extraTiresRengasCenter) extraTiresRengasCenter.text = extraTiresTextRengasCenter;
        } else {
          basicServices.push({
            id: EMaintenanceService.EXTRA_TIRES_EUROMASTER,
            accepted: false,
            text: extraTiresTextEuromaster,
            autoUpdate: true,
          });
          basicServices.push({
            id: EMaintenanceService.EXTRA_TIRES_RENGAS_CENTER,
            accepted: false,
            text: extraTiresTextRengasCenter,
            autoUpdate: true,
          });
        }
      }

      newInputs[EDealEdit.BASIC_SERVICES].value = [...basicServices];
      newInputs[EDealEdit.maintenanceServices].value = [...maintenanceServices];
      return newInputs;
    });

    // eslint-disable-next-line
  }, [
    leasingModel,
    variationContractLength,
    variationResidualValue,
    variationContractKilometers,
    variationExtraTireSets,
  ]); //, basicServices, redemption, extraTires]);

  // machine purchase price vat
  useEffect(() => {
    if (!isMachine) return;
    // console.log("useEffect() purchase price vat");
    setInputs((stateInputs) => {
      const newInputs = { ...stateInputs };
      console.log("vat", vatPercent)
      newInputs[EDealEdit.TOTAL_PRICE_VAT].value = totalPrice
        ? toTwoDecimals(totalPrice * vatPercent)
        : "";
      return newInputs;
    });
    // eslint-disable-next-line
  }, [totalPrice, vatPercent]);

  // validate inputs
  useEffect(() => {
    // skip validation on imported deals and contracts
    if (deal?.importName || deal?.status === EDealStatus.CONTRACT) {
      setValid(true);
      return;
    }

    const contactNameIsValid = validateInput(inputs, inputs[EDealEdit.contactPersonName]).isValid;
    const contactEmailIsValid = validateInput(inputs, inputs[EDealEdit.contactPersonEmail]).isValid;
    const contactPhoneIsValid = validateInput(inputs, inputs[EDealEdit.contactPersonPhoneNumber]).isValid;
    const dealershipIsValid = validateInput(inputs, inputs[EDealEdit.DEALERSHIP]).isValid;
    const insuranceIsValid = validateInput(inputs, inputs[EDealEdit.INSURANCE]).isValid;
    const financeCompanyIsValid = validateInput(inputs, inputs[EDealEdit.FINANCE_COMPANY]).isValid;
    // console.log("validation called");

    if (!contactNameIsValid || !contactEmailIsValid || !contactPhoneIsValid || !dealershipIsValid || !insuranceIsValid || !financeCompanyIsValid) {
      // console.log("not valid 1");
      // console.log("contactNameIsValid", contactNameIsValid);
      // console.log("contactEmailIsValid", contactEmailIsValid);
      // console.log("contactPhoneIsValid", contactPhoneIsValid);
      // console.log("dealershipIsValid", dealershipIsValid, dealership);
      // console.log("insuranceIsValid", insuranceIsValid);
      // console.log("financeCompanyIsValid", financeCompanyIsValid, financeCompany);
      setValid(false);
      return;
    }

    // ALSO CHECK useEffect in DealVariationEditCar + DealVariationEditMachine
    // check if gross profit is empty
    if (variations) {
      // console.log(variations);
      for (let i = 0; i < variations.length; i++) {
        const variation = variations[i];
        if (!variation.grossProfit || !variation.grossProfitVAT || !variation.contractLength) {
          // console.log("a variation is not valid");
          setValid(false);
          return;
        }
      }
    }

    if (!isCar) {
      // console.log("not car, set to valid");
      setValid(true);
      return;
    }

    // check if car tax is empty
    const carTaxIsValid = validateInput(inputs, inputs[EDealEdit.CAR_TAX]).isValid;
    if (!carTaxIsValid) {
      // console.log("not valid 2");
      setValid(false);
      return;
    }

    // console.log("end of use effect, set to valid");
    setValid(true);
    // eslint-disable-next-line
  }, [carTax, insurance, contactName, contactEmail, contactPhone, dealership, financeCompany, variations]);

  // car registration num = ref num
  useEffect(() => {
    if (!isCar) return;
    // if invoice reference is not empty or does not match registration number, don't continue
    if (invoiceReference && !registrationNumber.startsWith(invoiceReference))
      return;
    // if registration number is empty, don't continue
    if (!registrationNumber) return;
    setInputs((stateInputs) => {
      const newInputs = { ...stateInputs };
      newInputs[EDealEdit.INVOICE_REFERENCE].value = registrationNumber;
      return newInputs;
    });
    // eslint-disable-next-line
  }, [registrationNumber]);

  const createInput = (inputName: EDealEdit, options?: IInputOptions, hideValidation?: boolean) => {
    const item = inputs[inputName];
    return (
      <Input
        disabled={!editableFields.includes(inputName) && isDisabled}
        {...item}
        {...options}
        updateAction={EInputUpdateAction.DEAL_EDIT}
        onChange={(value) => {
          updateInputHandler(inputName, value, setInputs);
        }}
        inputName={inputName}
        onConditionChange={(condition) => {
          updateConditionHandler(inputName, condition, setInputs);
        }}
        showValidation={hideValidation ? false : !isValid}
      />
    );
  };

  const addVariation = () => {
    setVariations((stateVariations) => {
      const newVariations = [
        ...stateVariations,
        defaultPricingVariation(
          generateUUID(EDealEdit.DEAL_PRICING_VARIATIONS)
        ),
      ];
      return newVariations;
    });
  };

  const createInputs = () => {
    return (
      <>
        {isNew && (
          <>
            <InputGroup>{createInput(EDealEdit.TYPE)}</InputGroup>
          </>
        )}

        <Fieldset label={t(ETranslation.DEAL_CUSTOMER_INFORMATION)}>
          <InputGroup>
            {createInput(EDealEdit.CUSTOMER, { options: customerOptions })}
          </InputGroup>
          <hr />

          <InputGroup>{createInput(EDealEdit.CUSTOMER_NAME)}</InputGroup>
          <InputGroup>{createInput(EDealEdit.CUSTOMER_BUSINESS_ID)}</InputGroup>
          <InputGroup>
            {createInput(EDealEdit.CUSTOMER_STREET_ADDRESS)}
          </InputGroup>
          <InputGroup>
            {createInput(EDealEdit.CUSTOMER_ZIP)}
            {createInput(EDealEdit.CUSTOMER_CITY)}
          </InputGroup>

          <InputGroup>
            {createInput(EDealEdit.contactPersonName)}
            {createInput(EDealEdit.contactPersonEmail)}
            {createInput(EDealEdit.contactPersonPhoneNumber)}
          </InputGroup>
        </Fieldset>

        {isCar && (
          <Fieldset label={t(ETranslation.DEAL_USER_INFORMATION)} accordion>
            <InputGroup>{createInput(EDealEdit.USER_NAME)}</InputGroup>
            <InputGroup>{createInput(EDealEdit.USER_EMAIL)}</InputGroup>
            <InputGroup>{createInput(EDealEdit.USER_PHONE_NUMBER)}</InputGroup>
            <InputGroup>
              {createInput(EDealEdit.USER_SOCIAL_SECURITY_NUMBER)}
            </InputGroup>
            <InputGroup>
              {createInput(EDealEdit.USER_STREET_ADDRESS)}
            </InputGroup>
            <InputGroup>
              {createInput(EDealEdit.USER_ZIP)}
              {createInput(EDealEdit.USER_CITY)}
            </InputGroup>
          </Fieldset>
        )}

        <Fieldset label={t(ETranslation.DEAL_BASIC_INFORMATION)}>
          <InputGroup>
            {createInput(EDealEdit.FINANCE_COMPANY, {
              options: financeCompanyOptions,
            })}
            {createInput(EDealEdit.FINANCE_COMPANY_CONTRACT_NUMBER)}
            {createInput(EDealEdit.LEASING_MODEL)}
          </InputGroup>

          <InputGroup>{createInput(EDealEdit.NAME)}</InputGroup>

          <InputGroup>
            {createInput(EDealEdit.INVOICING_FEE)}
            {createInput(EDealEdit.OPENING_FEE)}
            {createInput(EDealEdit.vatPercent, { disabled: deal?.status && deal.status !== EDealStatus.OFFER })}
          </InputGroup>

          <InputGroup>{createInput(EDealEdit.invoiceInfo)}</InputGroup>

          <InputGroup>
            {createInput(EDealEdit.INVOICE_REFERENCE)}
            {createInput(EDealEdit.COST_CENTER)}
            {createInput(EDealEdit.INVOICE_PAYER)}
          </InputGroup>

          <InputGroup>
            {createInput(EDealEdit.DEALERSHIP, {
              labelTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP
                : ETranslation.DEAL_MACHINE_DEALERSHIP,
              placeholderTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP
                : ETranslation.DEAL_MACHINE_DEALERSHIP,
              options: dealershipOptions,
            },dealership ? true : false
            )}
            {createInput(EDealEdit.DEALERSHIP_SELLER_NAME, {
              labelTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP_SELLER_NAME
                : ETranslation.DEAL_MACHINE_DEALERSHIP_SELLER_NAME,
              placeholderTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP_SELLER_NAME
                : ETranslation.DEAL_MACHINE_DEALERSHIP_SELLER_NAME,
            })}
            {createInput(EDealEdit.DEALERSHIP_SALE_NUMBER, {
              labelTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP_SALE_NUMBER
                : ETranslation.DEAL_MACHINE_DEALERSHIP_SALE_NUMBER,
              placeholderTranslation: isCar
                ? ETranslation.DEAL_CAR_DEALERSHIP_SALE_NUMBER
                : ETranslation.DEAL_MACHINE_DEALERSHIP_SALE_NUMBER,
            })}
          </InputGroup>

          <InputGroup>
            {createInput(EDealEdit.REGISTRATION_NUMBER)}
            {isCar && (
              <InputGroup
                style={{ flexGrow: 0, margin: 0, alignItems: "center" }}
              >
                <Button
                  style={{ height: "fit-content" }}
                  onClick={() => biltemaHandler(registrationNumber)}
                  icon={faArrowRight}
                  loading={biltemaLoading}
                />
              </InputGroup>
            )}
            {createInput(EDealEdit.BRAND, {
              labelTranslation: isCar
                ? ETranslation.DEAL_CAR_BRAND
                : ETranslation.DEAL_MACHINE_BRAND,
              placeholderTranslation: isCar
                ? ETranslation.DEAL_CAR_BRAND
                : ETranslation.DEAL_MACHINE_BRAND,
              loading: biltemaLoading
            })}
            {createInput(EDealEdit.MODEL)}
            {createInput(EDealEdit.modelYear, { loading: biltemaLoading })}
            {createInput(EDealEdit.SERIAL_NUMBER, { loading: biltemaLoading })}
            {createInput(EDealEdit.REGISTRATION_DATE, { loading: biltemaLoading })}
          </InputGroup>

          {isCar && (
            <InputGroup>
              {createInput(EDealEdit.usedKilometers)}
              {createInput(EDealEdit.CO2_EMISSIONS)}
              {createInput(EDealEdit.TAKE_HOME_VEHICLE)}
              {createInput(EDealEdit.CAR_BENEFIT)}
            </InputGroup>
          )}

          <InputGroup>
            {isMachine && createInput(EDealEdit.usedHours)}
            {createInput(EDealEdit.SIGNING_DATE)}
            {createInput(EDealEdit.SURETY)}
            {createInput(EDealEdit.HANDOVER_DATE)}
          </InputGroup>

          <InputGroup>
            {createInput(EDealEdit.INSURANCE)}
            {createInput(EDealEdit.insuranceCertificateReceived)}
          </InputGroup>

          <InputGroup>
            {createInput(EDealEdit.ACCESSORIES)}
            {createInput(EDealEdit.ACCESSORIES2)}
          </InputGroup>

          <InputGroup>
            {isMachine && createInput(EDealEdit.TOTAL_PRICE)}
            {createInput(EDealEdit.TOTAL_PRICE_VAT, {
              disabled: (!authUser?.isAdmin && isDisabled) || isMachine,
              labelTranslation: isCar
                ? ETranslation.DEAL_CAR_TOTAL_PRICE
                : ETranslation.DEAL_MACHINE_PURCHASE_PRICE_VAT,
            })}
            {isCar && (
              <>
                {createInput(EDealEdit.DISCOUNT)}
                {createInput(EDealEdit.CAR_TAX)}
              </>
            )}
          </InputGroup>

          {isMachine && (
            <InputGroup>{createInput(EDealEdit.hideVatPrices)}</InputGroup>
          )}
        </Fieldset>

        {/* Variation */}
        {variations && (
          <DealVariationConditionContext.Provider
            value={{
              variationContractLength,
              variationResidualValue,
              variationContractKilometers,
              variationExtraTireSets,
              setVariationContractLength,
              setVariationResidualValue,
              setVariationContractKilometers,
              setVariationExtraTireSets,
            }}
          >
            {variations.map((variation, index) => (
              <DealVariationEdit
                key={variation.id}
                variation={variation}
                index={index + 1}
              />
            ))}
          </DealVariationConditionContext.Provider>
        )}
        {(!deal?.status || deal?.status === EDealStatus.OFFER) && (
          <Button onClick={addVariation}>
            {t(ETranslation.COMMON_ADD)} {t(ETranslation.DEAL_VARIATION)}
          </Button>
        )}

        <Fieldset label={t(ETranslation.DEAL_ADDITIONAL_EXPENSES)}>
          {additionalExpenses.map((fee) => (
            <ExtraFee
              key={fee.id}
              fee={fee}
              onCopy={(fee) =>
                copyExtraFee(
                  fee,
                  EDealEdit.ADDITIONAL_EXPENSES,
                  setAdditionalExpenses
                )
              }
              onRemove={(id) => removeExtraFee(id, setAdditionalExpenses)}
              onUpdate={(fee) => updateExtraFee(fee, setAdditionalExpenses)}
              disabled={!authUser?.isAdmin && isDisabled}
              attachmentsEnabled
            />
          ))}
          <Button
            onClick={() =>
              addExtraFee(EDealEdit.ADDITIONAL_EXPENSES, setAdditionalExpenses)
            }
          >
            {t(ETranslation.COMMON_ADD)}{" "}
            {t(ETranslation.DEAL_ADDITIONAL_EXPENSE)}
          </Button>
        </Fieldset>

        <Fieldset label={t(ETranslation.DEAL_BASIC_SERVICES)}>
          <InputGroup>
            {createInput(EDealEdit.BASIC_SERVICES, {
              onAdd: () => addCondition(EDealEdit.BASIC_SERVICES, setInputs, t),
              onRemove: (id) =>
                removeCondition(EDealEdit.BASIC_SERVICES, id, setInputs),
            })}
          </InputGroup>
        </Fieldset>

        <Fieldset label={t(ETranslation.DEAL_MAINTENANCE_SERVICES)}>
          <InputGroup>
            {createInput(EDealEdit.maintenanceServices, {
              onAdd: () => addCondition(EDealEdit.maintenanceServices, setInputs, t),
              onRemove: (id) =>
                removeCondition(EDealEdit.maintenanceServices, id, setInputs),
            })}
          </InputGroup>
        </Fieldset>

        <Fieldset label={t(ETranslation.DEAL_ADDITIONAL_INFORMATION)}>
          <InputGroup>
            {createInput(EDealEdit.ADDITIONAL_INFORMATION, {
              onAdd: () =>
                addCondition(EDealEdit.ADDITIONAL_INFORMATION, setInputs, t),
              onRemove: (id) =>
                removeCondition(
                  EDealEdit.ADDITIONAL_INFORMATION,
                  id,
                  setInputs
                ),
            })}
          </InputGroup>
        </Fieldset>

        {createInput(EDealEdit.ATTACHMENTS)}
      </>
    );
  };

  return (
    <>
      {createInputs()}
      <InputGroup>
        <Button
          onClick={(event) => saveOrUpdateDeal(event, inputs)}
          disabled={!isValid || editableFieldsDisabled}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
        <Button onClick={() => goBack()}>
          {t(ETranslation.COMMON_RETURN)}
        </Button>
        {!isNew && (
          <Button onClick={archiveDeal}>
            {deal?.archived
              ? t(ETranslation.DEAL_ACTION_UNARCHIVE)
              : t(ETranslation.DEAL_ACTION_ARCHIVE)}
          </Button>
        )}
        {deal?.status === EDealStatus.CONTRACT && (
          <Button onClick={setDealTerminateDialog}>
            {t(ETranslation.DEAL_ACTION_TERMINATE)}
          </Button>
        )}
      </InputGroup>
    </>
  );
};

export default DealEdit;
